import { API_ROUTER } from "../../../api";
import { FormattedMessage } from "react-intl";

export const MATCHES_FILTER = [
  { id: 0, value: <FormattedMessage id="matches.filter.showAll" /> },
  {
    id: 1,
    value: <FormattedMessage id="matches.filter.showOnlyMy" />,
  },
];
export const MATCHES_FILTER_PIBLIC = [
  {
    id: 0,
    value: "Show all matches",
  },
];

export const MATCHES_STATE_FILTER = [
  { id: "soon", value: <FormattedMessage id="battlecup.matches.soon" /> },
  { id: "ended", value: <FormattedMessage id="battlecup.matches.ended" /> },
  { id: "live", value: <FormattedMessage id="battlecup.matches.live" /> },
  {
    id: "canceled",
    value: <FormattedMessage id="battlecup.matches.canceled" />,
  },
];

export const MATCHES_STATUS_FILTER = [
  { id: "future", value: "future" },
  { id: "current", value: "current" },
  { id: "past", value: "past" },
  { id: "canceled", value: "canceled" },
];
