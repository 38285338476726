import React, { useState } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import Card from "../Card";

import styles from "./styles.module.scss";

export default function Row({
  title = "Title",
  cards = [],
  history,
  requestSuccess,
}) {
  return (
    <>
      <div className={styles.styledRow}>
        <section className={styles.styledRow__header}>
          <h1>{title}</h1>
        </section>
        <section className={styles.styledRow__footer}>
          {!requestSuccess && <CircularProgress />}
          {!!requestSuccess && cards.length ? (
            cards.map((card) => (
              <Card {...card} key={uuidv4()} history={history} />
            ))
          ) : (
            <div className="empty-data">There are no tournaments yet</div>
          )}
        </section>
      </div>
    </>
  );
}
