import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";
import { MATCH_STATUS_ENDED, MATCH_STATUS_TECHNICAL_DEFEAT } from "./constants";
import styles from "./styles.module.scss";

export const MatchInformationSectionDefault = ({
  match,
  loser,
  getScoreCommand,
  getStartMatchDate,
  getEndMatchDate,
  getMemberUrl,
  getNameTeam,
  getLogoTeam,
}) => {
  return (
    <section className={classNames("matchInfoScoreboardBg", styles.scoreboard)}>
      <article
        className={styles.scoreboardTeam}
        onClick={() => getMemberUrl(match?.tournamentMember1)}
      >
        <div className={styles.teamImageWrapper}>
          <img
            className={styles.img}
            src={getLogoTeam(match?.tournamentMember1)}
            alt="team-logo"
          />
        </div>

        <h5 className={styles.teamTitle}>
          {getNameTeam(match?.tournamentMember1)}
        </h5>
      </article>
      <article className={styles.scoreWrapper}>
        <div className={styles.timeMatch}>
          {match?.status === MATCH_STATUS_ENDED
            ? getEndMatchDate(match)
            : getStartMatchDate(match)}
        </div>

        <div className={styles.scoreParameters}>
          <p className={classNames("matchInfoStatusBg", styles.status)}>
            {match?.status}
          </p>
          <p className={styles.bestOf}>{match?.mode}</p>
          <p>
            <FormattedMessage id="match.ticker.layer" />:{" "}
            {match?.tournament?.layer}
          </p>
          <p>
            <FormattedMessage id="match.ticker.stage" />: {match?.round?.name}
          </p>
        </div>
        <div className={styles.score}>
          <p>{getScoreCommand(match?.tournamentMemberScore1)}</p>
          <p>:</p>
          <p>{getScoreCommand(match?.tournamentMemberScore2)}</p>
        </div>
        {/* {match?.matchResultStatus &&
          match?.matchResultStatus === MATCH_STATUS_TECHNICAL_DEFEAT && (
            <div className={styles.defeat}>
              <FormattedMessage id="match.ticker.technicalDefeat" />
              <span className={classNames("matchInfoLoserColor", styles.loser)}>
                {"  "} {loser}
              </span>
            </div>
          )} */}
      </article>
      <article
        className={styles.scoreboardTeam}
        onClick={() => getMemberUrl(match?.tournamentMember2)}
      >
        <div className={styles.teamImageWrapper}>
          <img
            src={getLogoTeam(match?.tournamentMember2)}
            alt="logoTeam2"
            className={styles.img}
          />
        </div>
        <h5 className={styles.teamTitle}>
          {getNameTeam(match?.tournamentMember2)}
        </h5>
      </article>
    </section>
  );
};
export const MatchInformationSectionFFA = ({
  match,
  getStartMatchDate,
  getEndMatchDate,
}) => {
  return (
    <section
      style={{
        maxWidth: "500px",
        display: "flex",
      }}
      className={classNames("matchInfoScoreboardBg", styles.scoreboard)}
    >
      <article className={styles.scoreWrapper}>
        <div className={styles.timeMatch}>
          {match?.status === MATCH_STATUS_ENDED
            ? getEndMatchDate(match)
            : getStartMatchDate(match)}
        </div>

        <div className={styles.scoreParameters}>
          <p className={classNames("matchInfoStatusBg", styles.status)}>
            {match?.status}
          </p>
          <p className={styles.bestOf}>{match?.mode}</p>
          <p>
            <FormattedMessage id="match.ticker.layer" />:{" "}
            {match?.tournament?.layer}
          </p>
          <p>
            <FormattedMessage id="match.ticker.stage" />: {match?.round?.name}
          </p>
        </div>
      </article>
    </section>
  );
};
