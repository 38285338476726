import {
  CommunityList,
  EventsList,
  TournamentsList,
  UsersList,
} from "../components";
import { FormattedMessage } from "react-intl";
import React from "react";

export const MENU_TABS = [
  {
    id: "0",
    title: "navigation.community",
    route: "/followed/community",
    components: () => <CommunityList />,
  },
  {
    id: "1",
    title: "calendar.events",
    route: "/followed/events",
    components: () => <EventsList />,
  },
  {
    id: "2",
    title: "calendar.tournaments",
    route: "/followed/tournaments",
    components: () => <TournamentsList />,
  },
  {
    id: "3",
    title: "calendar.users",
    route: "/followed/users",
    components: () => <UsersList />,
  },
];

export const MONTHS_LIST = [
  {
    id: "2022-01",
    value: <FormattedMessage id="calendar.filter.month.jan" />,
    month: "2022-01",
  },
  {
    id: "2022-02",
    value: <FormattedMessage id="calendar.filter.month.feb" />,
    month: "2022-02",
  },
  {
    id: "2022-03",
    value: <FormattedMessage id="calendar.filter.month.march" />,
    month: "2022-03",
  },
  {
    id: "2022-04",
    value: <FormattedMessage id="calendar.filter.month.apr" />,
    month: "2022-04",
  },
  {
    id: "2022-05",
    value: <FormattedMessage id="calendar.filter.month.may" />,
    month: "2022-05",
  },
  {
    id: "2022-06",
    value: <FormattedMessage id="calendar.filter.month.jun" />,
    month: "2022-06",
  },
  {
    id: "2022-07",
    value: <FormattedMessage id="calendar.filter.month.jul" />,
    month: "2022-07",
  },
  {
    id: "2022-08",
    value: <FormattedMessage id="calendar.filter.month.aug" />,
    month: "2022-08",
  },
  {
    id: "2022-09",
    value: <FormattedMessage id="calendar.filter.month.sep" />,
    month: "2022-09",
  },
  {
    id: "2022-10",
    value: <FormattedMessage id="calendar.filter.month.oct" />,
    month: "2022-10",
  },
  {
    id: "2022-11",
    value: <FormattedMessage id="calendar.filter.month.nov" />,
    month: "2022-11",
  },
  {
    id: "2022-12",
    value: <FormattedMessage id="calendar.filter.month.dec" />,
    month: "2022-12",
  },
];
