import React from "react";
import moment from "moment";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import noImage from "../../../../assets/images/no-image.webp";
import styles from "./styles.module.scss";
import { MATCH_STATUS_SOON } from "../../../match-discussion/constants";

const MatchItem = (props) => {
  const {
    tournamentName,
    name,
    tournamentMember1,
    tournamentMemberScore1,
    status,
    mode,
    startedAt,
    tournamentMemberScore2,
    tournamentMember2,
    tournamentStageType,
    game,
    round,
  } = props;

  const getImageUrl = (val) => {
    if (val?.team?.logo) {
      return val?.team?.logo;
    }

    if (val?.team?.avatar) {
      return val?.team?.avatar;
    }

    return noImage;
  };

  const getNameTeam = (val) => {
    if (val?.user?.nickname) {
      return val?.user?.nickname;
    }

    if (val?.team?.name) {
      return val?.team?.name;
    }

    return "";
  };

  const isNumber = (val) => {
    return Number.isFinite(val) ? val : "-";
  };

  const getStartMatchDate = (date) => {
    const testDateUtc = moment.utc(date);
    return moment(testDateUtc).local().format("D MMMM YYYY, h:mm a (UTC Z)");
  };

  return (
    <Link
      to={name ? `/match-discussion/${name}` : "#"}
      className={classNames("matchItemBg", styles.matchesItemContainer)}
    >
      <div className={styles.title}>{tournamentName}</div>

      <div className={styles.resultContainer}>
        {/* проверка если это FFA то показывать такой контент */}
        {tournamentStageType === "FFA" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
            className={styles.teamsWrapper}
          >
            <div className={styles.teamDescription}>
              <div
                style={{
                  fontSize: "20px",
                }}
                className={styles.name}
              >
                {name ? name : ""}
              </div>
            </div>
            <div className={styles.teamDescription}>
              <div
                style={{
                  fontSize: "20px",
                }}
                className={styles.name}
              >
                {game?.name ? game?.name : game?.title ? game?.title : ""}
              </div>
            </div>

            <div className={styles.teamDescription}>
              <div
                style={{
                  fontSize: "20px",
                }}
                className={styles.name}
              >
                <FormattedMessage id="matches.tournament.ffa" />
              </div>
            </div>
            <div className={styles.teamDescription}>
              <div
                style={{
                  fontSize: "20px",
                }}
                className={styles.name}
              >
                <FormattedMessage id="matches.round" /> {round?.name}
              </div>
            </div>
            <div className={styles.teamDescription}>
              <div
                style={{
                  fontSize: "20px",
                }}
                className={styles.name}
              >
                <FormattedMessage id="matches.status" /> {status}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.teamsWrapper}>
            <div style={{ margin: "10px" }} className={styles.name}>
              {name ? name : ""}
            </div>
            <div style={{ margin: "10px" }} className={styles.name}>
              {game?.name}
            </div>
            <div className={styles.teamDescription}>
              <img
                className={styles.img}
                src={getImageUrl(tournamentMember1)}
                alt="tournament-member-1"
              />
              <div className={styles.name}>
                {getNameTeam(tournamentMember1)}
              </div>
            </div>
            <div className={styles.scoreWrapper}>
              {status === MATCH_STATUS_SOON ? (
                <>
                  <div className={styles.score}>-</div>
                  <div className={styles.score}>:</div>
                  <div className={styles.score}>-</div>
                </>
              ) : (
                <>
                  <div className={styles.score}>
                    {isNumber(tournamentMemberScore1)}
                  </div>
                  <div className={styles.score}>-</div>
                  <div className={styles.score}>
                    {isNumber(tournamentMemberScore2)}
                  </div>
                </>
              )}
            </div>
            <div className={styles.teamDescription}>
              <img
                className={styles.img}
                src={getImageUrl(tournamentMember2)}
                alt="tournament-member-2"
              />
              <div className={styles.name}>
                {getNameTeam(tournamentMember2)}
              </div>
            </div>
          </div>
        )}

        <div
          className={classNames("tournamentImageBg", styles.tournamentImage)}
        ></div>
      </div>
      <div className={styles.time}>{getStartMatchDate(startedAt)}</div>
      {!!mode && <div className={styles.scoreDay}>{mode}</div>}
    </Link>
  );
};

export default MatchItem;
