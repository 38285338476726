import en from "./translations/en.json";
import ru from "./translations/ru.json";
import pt from "./translations/pt.json";
import fr from "./translations/fr.json";
import es from "./translations/es.json";
// import pl from './translations/pl.json';
// import cn from './translations/cn.json';
// import it from './translations/it.json';
import zh from "./translations/china.json";
import tg from "./translations/tg.json";
import ar from "./translations/ar.json";
export const translations = {
  en,
  ru,
  pt,
  fr,
  es,
  zh,
  tg,
  ar,
  // pl,
  // cn,
  // it
};
