import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { LinearProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

import { showQrModal, getUserData } from "../../../../redux/actions";

import { API, API_ROUTER } from "../../../../api";

import { isAuthenticated } from "../../../../helpers";
import getPublicUserProfile from "../../../../helpers/user/getPublicUserProfile";

import ProfileModalInformation from "../../modal/more-info-user";

import { ContentBox, Button } from "../../../../components/UI";
import { userFlag } from "../../../../components/cards/userFlag";

import profLogo from "../../../../assets/images/bg.png";
import verefied from "../../../../assets/icons/verification.svg";
import baner from "../../../..//assets/images/euroevent25may.jpg";

import Styled from "../../style/styleUserAbout";
import styles from "./styles.module.scss";
const Top = ({ dispatchShowQrModal, setShowTeamModal, userData, url, id }) => {
  const [userProfile, setUserProfile] = useState();
  const [heart, tongleHeart] = useState("false");
  const [image, setImage] = useState(null);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [star, tongleStar] = useState(false);
  const [openMoreInfoModal, setOpenMoreInfoModal] = useState(!true);

  const countriesList = useSelector((state) => state.countriesList);
  useEffect(() => {
    chekUserProfile();
  }, []);

  const chekUserProfile = async () => {
    setRequestSuccess(false);
    if (id === undefined) {
      return;
    }
    await getPublicUserProfile(url)
      .then((res) => {
        setUserProfile(res);
        setRequestSuccess(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setRequestSuccess(true));
  };

  const imgSet = (file) => {
    setImage(() => file);
  };
  const openModal = (e) => {
    dispatchShowQrModal();
  };

  function changeImg(e) {
    const file = e.target.files[0];
    if (file) {
      const params = {
        ...API_ROUTER.user.setUserData,
      };

      const formData = new FormData();

      formData.append("media", file);
      formData.append("mediaType", "userAvatar");
      formData.append("mediaOwnerId", userData.uuid);

      const avatarParams = {
        ...API_ROUTER.media.setAvatar,
        headers: {
          "Content-type": "multipart/form-data",
        },
        data: formData,
      };

      return API.request(avatarParams, true)
        .then((res) => {
          console.log("avatarParams", avatarParams);
          console.log("res in uploading avatar", res);
        })
        .catch((e) => {
          console.log("params", avatarParams);
          console.log("avatarParams", avatarParams);
          console.log("e in avatar request", e);
        });
    }
  }

  function isCreator() {
    return userProfile?.id === userData?.id;
  }

  function getCountryFromValue(country) {
    const fullCountry = countriesList.find((item) => item?.value === country);
    return fullCountry?.label;
  }

  const tongStar = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowUser,
      pathKeys: {
        favoriteId: userProfile?.id,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowUser,
              pathKeys: {
                favoriteId: userProfile?.id,
              },
            })
              .then((res) => {
                toast.success(`You unfollowed ${userProfile?.nickname} `);
                tongleStar(false);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followUser,
              data: {
                favoriteId: userProfile?.id,
              },
            })
              .then((res) => {
                toast.success(`You followed ${userProfile?.nickname}`);
                tongleStar(true);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));
    // if (star) {
    //   tongleStar(true);
    // } else tongleStar(false);
  };

  const RenderDetails = ({ country, firstName, about, flag }) => {
    return (
      <div>
        {!!firstName && (
          <div className="dataRow row-type">
            <div className={classNames("userAboutColor")}>
              <FormattedMessage id="global.forms.labels.firstName" />
            </div>
            <div>{firstName}</div>
          </div>
        )}
        {!!country && (
          <div className="dataRow row-type">
            <div className={classNames("userAboutColor")}>
              <FormattedMessage id="id.country" />
            </div>

            <div>
              <div style={{ justifyContent: "flex-start" }}>
                {userFlag(flag).defaultCard}
              </div>
              {getCountryFromValue(country)}
            </div>
          </div>
        )}
        {!!about && (
          <div className="dataRow row-type">
            <div className={classNames("userAboutColor")}>
              <FormattedMessage id="id.bio" />
            </div>
            <div> {about}</div>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      {requestSuccess ? (
        <>
          <div className={classNames("contentBoxColor", styles.contentBox)}>
            <Styled.Row>
              <Styled.Col>
                <Styled.StyledInnerBox>
                  <Styled.Content>
                    {isCreator() ? (
                      <>
                        <div
                          className={classNames("userAboutColor", "nickname")}
                        >
                          {userData?.nickname ? userData?.nickname : ""}
                        </div>
                        <Styled.AvatarHolder>
                          <Styled.Avatar
                            className={userData?.topGamer ? "top-gamer" : ""}
                            image={
                              userData?.avatar ? userData?.avatar : profLogo
                            }
                            pro={userData?.pro}
                          />
                          {!!userData?.verified && (
                            <Styled.VerifiedStatus
                              verified={userData?.verified}
                            />
                          )}
                        </Styled.AvatarHolder>
                        <div className="username">
                          {userData?.firstName ? userData?.firstName : ""}{" "}
                          {userData?.lastName ? userData?.lastName : ""}
                        </div>

                        <div className="role">
                          <FormattedMessage id="global.roles.gamer" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="nickname">
                          {userProfile?.nickname ? userProfile?.nickname : ""}
                        </div>
                        <Styled.AvatarHolder>
                          <Styled.Avatar
                            className={userProfile?.topGamer ? "top-gamer" : ""}
                            image={
                              userProfile?.avatar
                                ? userProfile?.avatar
                                : profLogo
                            }
                            pro={userProfile?.pro}
                          />
                          {!!userProfile?.verified && (
                            <Styled.VerifiedStatus
                              verified={userProfile?.verified}
                            />
                          )}
                        </Styled.AvatarHolder>
                        <div className="username">
                          {userProfile?.firstName ? userProfile?.firstName : ""}{" "}
                          {userProfile?.lastName ? userProfile?.lastName : ""}
                        </div>
                        <div className="role">
                          <FormattedMessage id="global.roles.gamer" />
                        </div>
                      </>
                    )}
                  </Styled.Content>
                </Styled.StyledInnerBox>
              </Styled.Col>
              <Styled.Col>
                <Styled.StyledInnerBox>
                  <Styled.Content>
                    <div className="sectionTitle moreInfo">
                      <FormattedMessage id="id.about" />
                      <div
                        onClick={() => setOpenMoreInfoModal(!openMoreInfoModal)}
                        className={classNames("mainColorfulText", "moreInfo")}
                      >
                        <FormattedMessage id="profile.more.info" />
                      </div>
                    </div>
                    {isCreator() ? (
                      <RenderDetails
                        country={userData?.country}
                        firstName={userData?.firstName}
                        about={userData?.about}
                        flag={userData?.flag}
                      />
                    ) : (
                      <RenderDetails
                        country={userProfile?.country}
                        firstName={userProfile?.firstName}
                        about={userProfile?.about}
                        flag={userProfile?.flag}
                      />
                    )}
                  </Styled.Content>
                </Styled.StyledInnerBox>
              </Styled.Col>

              {!isCreator() && isAuthenticated() && (
                <Styled.Col className="controls">
                  <Button
                    action={() => toast.error("Wait soon in future apdate")}
                    label={<FormattedMessage id="id.addFriend" />}
                  />
                  {star === true ? (
                    <Button
                      action={() => tongStar()}
                      variant="secondary"
                      label={<FormattedMessage id="id.follow" />}
                    />
                  ) : (
                    <Button
                      action={() => tongStar()}
                      variant="secondary"
                      label={"Unfollow"}
                    />
                  )}

                  <Button
                    action={() => {
                      openModal();
                      setShowTeamModal(true);
                    }}
                    variant="secondary"
                    label={<FormattedMessage id="id.offerToPlay" />}
                  />
                </Styled.Col>
              )}
            </Styled.Row>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
      {/* SHOW MODAL INFO USER */}
      {isCreator() ? (
        <ProfileModalInformation
          showQrModal={openMoreInfoModal}
          setOpenModal={setOpenMoreInfoModal}
          citizenship={userData?.citizenship}
          country={userData?.country}
          firstName={userData?.firstName}
          lastName={userData?.lastName}
          age={userData?.age}
          gender={userData?.gender}
          passport={userData?.passport}
          discord={userData?.discord}
          sites={userData?.sites}
        />
      ) : (
        <ProfileModalInformation
          showQrModal={openMoreInfoModal}
          setOpenModal={setOpenMoreInfoModal}
          citizenship={userProfile?.citizenship}
          country={userProfile?.country}
          firstName={userProfile?.firstName}
          lastName={userProfile?.lastName}
          age={userProfile?.age}
          gender={userProfile?.gender}
          passport={userProfile?.passport}
          discord={userProfile?.discord}
          sites={userProfile?.sites}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    runGetUserData: () => dispatch(getUserData()),
    dispatchShowQrModal: () => dispatch(showQrModal()),
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Top));
