import React, { useEffect, useState } from "react";
import { API, API_ROUTER } from "../../api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const NotificationItem = ({ id, message, viewed, action, metadata, type }) => {
  const [checked, setChecked] = useState("false");
  const [activeInvit, setActiveInvit] = useState(true);
  const [delet, setDeleted] = useState(false);
  const history = useHistory();

  const check = () => {
    if (checked) {
      setChecked(true);
    }
  };
  function viewedNotification(id) {
    const params = {
      ...API_ROUTER.notifications.markSingleAsViewed,
      pathKeys: {
        id: id,
      },
    };

    return API.request(params);
  }
  function deleteNotifications(id) {
    const params = {
      ...API_ROUTER.notifications.deleteSingleNotification,
      pathKeys: {
        id: id,
      },
    };

    return API.request(params);
  }

  const answerInvite = (answer) => {
    setActiveInvit(false);
    API.request({
      ...API_ROUTER.teams.setAnswerInvites,
      pathKeys: {
        teamId: metadata?.team?.id,
      },
      data: {
        status: answer,
      },
    }).catch((err) => toast.error(err.data?.message));
  };

  const renderNotificationItem = (type) => {
    if (!delet & (action === "team-invite")) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <div
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              <a
                className="notification__result-item-nickname"
                href={`/id/${metadata?.inviter?.url}`}
                // onClick={() => history.push(`/id/${metadata?.inviter?.url}`)}
              >
                {metadata?.inviter?.nickname}
              </a>{" "}
              <div className="notification__result-item-message">
                invites you to join the team
              </div>
              <a
                className="notification__result-item-nickname"
                href={`/teams/team/${metadata?.team?.id}`}
                // onClick={() =>
                //   history.push(`/teams/team/${metadata?.team?.id}`)
                // }
              >
                {metadata?.team?.nickname}
              </a>
            </div>
            {(viewed === true) | checked ? (
              false
            ) : (
              <div className="notification__button-box">
                <button
                  className="notification__accept-button"
                  onClick={() => {
                    answerInvite("accepted");
                    toast.success("accepted");
                    viewedNotification(id);
                    check();
                  }}
                >
                  accept
                </button>
                <button
                  className="notification__reject-button"
                  onClick={() => {
                    answerInvite("rejected");
                    viewedNotification(id);
                    toast.success("rejected");
                    check();
                  }}
                >
                  refuse
                </button>
              </div>
            )}

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((type === "warning") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--red"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
              //
            >
              {message}
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }

    if ((type === "ads") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--black"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              {message}
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((type === "error") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--red"
          }
        >
          <li
            className="notification__result-item"
            style={{ background: "#ef523624" }}
          >
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              {message}
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((type === "partners") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--white"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              {message}
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((type === "system") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              {message}
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((type === "success") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              {message}
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }

    if ((action === "tournament-invite") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              <div
                className="notification__result-item-nickname"
                onClick={() => history.push(`/id/${metadata?.inviter?.id}`)}
              >
                {metadata?.inviter?.nickname}
              </div>{" "}
              <div className="notification__result-item-message">announced</div>
              <div
                className="notification__result-item-nickname"
                onClick={() =>
                  history.push(
                    `/battleCup/${metadata?.tournament?.id}/information`
                  )
                }
              >
                {metadata?.tournament?.name}
              </div>
              <div className="notification__result-item-message">
                Check it out
              </div>
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "event-invite") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              <div
                className="notification__result-item-nickname"
                onClick={() => history.push(`/id/${metadata?.inviter?.id}`)}
              >
                {metadata?.inviter?.nickname}
              </div>{" "}
              <div className="notification__result-item-message">announced</div>
              <div
                className="notification__result-item-nickname"
                onClick={() =>
                  history.push(`/eventPage/${metadata?.event?.id}`)
                }
              >
                {metadata?.event?.name}
              </div>
              <div className="notification__result-item-message">
                Check it out
              </div>
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "community-invite") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              community-invite
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "friendship") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              notification__result-item
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "match_confirmation") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              match_confirmation
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "offer-to-play") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              offer-to-play
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "rating") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              rating
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "team-member-leave") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              team-member-leave
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "match-will-begin-soon") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </a>
            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "match-finished") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              match-finished
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "chat_message") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              <p>chat_message</p>
              <p>chat_message</p>
              <p>chat_message</p>
              <p>chat_message</p>
              <p>chat_message</p>
              <p>chat_message</p>
              <p>chat_message</p>
              <p>chat_message</p>
              <p>chat_message</p>
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
    if ((action === "match-started") & !delet) {
      return (
        <ul
          className={
            (viewed === true) | checked
              ? "notification__result-list"
              : "notification__result-list notification__result-list--blue"
          }
        >
          <li className="notification__result-item">
            <a
              className={
                (viewed === true) | checked
                  ? "notification__result-item-link-viewed"
                  : "notification__result-item-link"
              }
            >
              chat_message
            </a>

            <div className="notification__icon-box">
              <div className="notification__icon">
                <i
                  className="icon icon-delete"
                  onClick={() => {
                    setDeleted(true);
                    deleteNotifications(id);
                  }}
                />
              </div>
              {(viewed === false) | checked ? (
                <div className="notification__icon">
                  <i
                    className="icon icon-eye"
                    onClick={() => {
                      viewedNotification(id);
                      check();
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </li>
        </ul>
      );
    }
  };

  return <>{renderNotificationItem(type)}</>;
};

export default NotificationItem;
