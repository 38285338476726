import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { API, API_ROUTER } from "../../../../api";

import CommunityItem from "../../../../components/list-items/components/communityItem";
import { Preloader } from "../../../../components/UI";

import { isAuthenticated } from "../../../../helpers";

import { CustomSelect } from "../../../../components/form";

import { COMMUNITY_HAS_PRO_STATUS } from "./constants";

import styles from "./styles.module.scss";
import { Close } from "@material-ui/icons";

export const CommunityList = () => {
  const [communityList, setCommunityList] = useState();
  const [pageCommunity, setPageCommunity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthUser, setIsAuthUser] = useState(isAuthenticated());
  const [defaultStatus, setDefaultStatus] = useState(null);

  const getCommunity = () => {
    setIsLoading(true);
    const useParams = {};

    if (defaultStatus) {
      useParams["allowIsPro"] = defaultStatus.id;
    }

    API.request({
      useParams,
      ...API_ROUTER.follow.getfollowedCommunities,
      pathKeys: {
        page: pageCommunity,
        limit: 10,
      },
    })
      .then((res) => {
        setCommunityList(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleChangeCommunities = (event, value) => {
    setPageCommunity(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const changeShowPro = (val) => {
    const newVal = COMMUNITY_HAS_PRO_STATUS?.find((el) => el.id === val);

    if (newVal) {
      setDefaultStatus(newVal);
    }
  };

  const clearFilters = () => {
    setDefaultStatus(null);
  };

  useEffect(() => {
    getCommunity();
  }, [pageCommunity, defaultStatus]);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <section className={styles.calendarList}>
      {/* <div className={styles.filters}>
        <CustomSelect
          onChange={changeShowPro}
          label="community.status.selectCommunityStatus"
          options={COMMUNITY_HAS_PRO_STATUS}
          defaultValue={defaultStatus}
        />
        <button className={styles.clearButton} onClick={clearFilters}>
   
          <Close />
        </button>
      </div> */}

      {!communityList?.follows?.length && (
        <div className="empty-data">
          <FormattedMessage id="calendar.noCommunities" />
        </div>
      )}

      <article>
        <ul>
          {communityList?.follows?.map((communities) => (
            <CommunityItem {...communities} key={communities.id} />
          ))}
        </ul>
      </article>

      {!communityList?.paginator?.hasNextPage |
      (communityList?.follows?.length > 9) ? (
        <div className={styles.paginatorWrapper}>
          <Pagination
            classes={{ ul: styles.pagination }}
            count={communityList?.paginator?.lastPage}
            page={communityList?.paginator.currentPage}
            onChange={handleChangeCommunities}
            variant="outlined"
          />
        </div>
      ) : (
        false
      )}
    </section>
  );
};

export default CommunityList;
