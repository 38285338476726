import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";

import Button from "../../components/UI/buttons/buttons-login_register/button/Button";
import pubg_logo from "../../assets/images/pubg.png";

export default function CupInformation({ tournament }) {
  return (
    <StyledCupInformation>
      <section className={classNames("cupRulesBg", "tour__rules")}>
        <h1>
          <FormattedMessage id="battlecup.information.tournamentRules" />
        </h1>
        {tournament?.details?.rules ? (
          <p>{tournament?.details?.rules}</p>
        ) : (
          <FormattedMessage id="battlecup.information.noTournamentRules" />
        )}
      </section>
    </StyledCupInformation>
  );
}

const StyledCupInformation = styled.div`
  padding: 25px;

  & > section {
    &.tour__rules {
      border-radius: 10px;

      padding: 10px 18px;
      margin-bottom: 16px;

      & > h1 {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.61px;
        line-height: normal;
        border-bottom: 1px solid grey;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }

      & > ul {
        li {
          font-size: 13px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: -0.51px;
          line-height: normal;
          margin: 10px;
        }
      }

      & > a {
        color: #f7a01d;
        font-size: 11px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.41px;
        line-height: normal;
        display: block;
        width: max-content;
        margin-left: auto;
        margin-top: 5px;
      }
    }
  }
`;
