import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, withRouter, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

//
import TournamentDoesnotExistModal from "./modal/noTournament";
import Button from "../UI/buttons/buttons-login_register/button/Button";
import CupTab from "../CupTab";

import banerAsia from "../../assets/images/event-asia-baner.png";
import { toast } from "react-toastify";
import { convertDate } from "../../helpers/dates-formatter";
import { CircularProgress } from "@material-ui/core";
import { isAuthenticated } from "../../helpers";

import { API, API_ROUTER } from "../../api";

import styles from "./styles.module.scss";
//
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    backgroundColor: "#1f1941",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
export function Top({ tournamentDoesnotExistModal, tournament }) {
  let { id } = useParams();
  const history = useHistory();

  const [gameNickname, setGameNickname] = useState("");
  const [userData, setUserData] = useState(null);
  const [isUserDataParicipant, setIsUserDataParicipant] = useState();
  const [userGames, setUserGames] = useState();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [openMoreInfoModal, setOpenMoreInfoModal] = useState(!true);
  const [errorMessage, setErrorMessage] = useState("");

  const getMyData = () => {
    API.request({
      ...API_ROUTER.user.getMyData,
    })
      .then((res) => {
        setUserData(res);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  };

  const getUserGames = () => {
    API.request({
      ...API_ROUTER.games.getUserGames,
    })
      .then((res) => {
        setUserGames(res.games);
      })
      .catch((err) => console.log(err));
  };

  function addGame() {
    const data = {
      gameId: tournament?.game?.id,
      nickname: gameNickname,
    };
    API.request({
      ...API_ROUTER.games.addGameToUserGames,
      data,
    })
      .then(() => {
        toast.success("Game successfully added");
        window.location.reload();
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  }
  const submitTeamRoster = () => {
    API.request({
      ...API_ROUTER.tournaments.postTeamRoster,
      pathKeys: {
        tournamentId: tournament?.id,
      },
      data: {
        user: {
          id: userData?.id,
          nickname: userData?.nickname,
        },
        team: null,
      },
    })
      .then((res) => {
        toast.success("Your registration successfully");
      })
      .catch((err) => err?.data?.message && toast.error(err?.data?.message));
  };
  const checkDetails = () => {
    // if (tournament?.details?.minimumAge > userData?.age) {
    //   setErrorMessage(
    //     "Player must be at least " + tournament?.details?.minimumAge
    //   );
    // } else if (tournament?.details?.gender !== userData?.gender) {
    //   setErrorMessage("Player gender must be " + tournament?.details?.gender);
    // } else if (!tournament?.details?.countries.includes(userData?.country)) {
    //   setErrorMessage(
    //     `Players from ${userData?.country} not available for this tournament`
    //   );
    // } else {
    //   setErrorMessage("");
    // }
  };
  function showErrorToast() {
    toast.success(errorMessage);
  }
  console.log(tournament?.details?.gender);
  // ПРОВЕРКА ЕСТЬ ЛИ У ЮЗЕРА ИГРА ТУРНИРА

  const hasUserGame = () => {
    const games = [];
    userGames?.map((i) => games.push(i.game.id));
    return games.includes(tournament?.game.id);
  };

  // ЕСЛИ ТИП ЮЗЕР ТО ОТПРАВЛЯЕТСЯ АЙДИ И NICK
  const checkParticipationTypeData = (type, fn) => {
    type === "user"
      ? submitTeamRoster()
      : fn(`/battleCup/${tournament?.id}/registrationTournament`);
  };

  const goToUrl = (url) => {
    history.push(url);
    if (!isAuthenticated()) {
      localStorage.removeItem("eventId");
      localStorage.removeItem("communityId");
      localStorage.removeItem("profileUrl");
      return localStorage.setItem("tournamentId", id);
    }
  };

  const navList = (id) => [
    {
      id: uuidv4(),
      title: (
        <FormattedMessage id="tournaments.details.info.tabs.information" />
      ),
      to: `/battleCup/${id}/information`,
    },

    // ЗАБЛОЧЕНО ДЛЯ ТУРНИРОВ АЗИИ

    {
      id: uuidv4(),
      title: <FormattedMessage id="tournaments.details.info.tabs.brackets" />,
      // to1: `/battleCup/${id}/bracket/preliminary_tournament`,
      to:
        tournament?.stages?.length > 1
          ? `/battleCup/${id}/bracket/group_stage`
          : `/battleCup/${id}/bracket/play_off`,
      to1:
        tournament?.stages?.length > 1
          ? `/battleCup/${id}/bracket/group_stage`
          : `/battleCup/${id}/bracket/play_off`,
    },
    {
      id: uuidv4(),
      title: <FormattedMessage id="game.tournaments.details.rules" />,
      to: `/battleCup/${id}/rules`,
    },
    {
      id: uuidv4(),
      title: <FormattedMessage id="tournaments.details.info.tabs.matches" />,
      to: `/battleCup/${id}/matches`,
    },

    {
      id: uuidv4(),
      title: (
        <FormattedMessage id="tournaments.details.info.tabs.participants" />
      ),
      to: `/battleCup/${id}/participants`,
    },
    // { id: uuidv4(), title: "STATISTICS", to: `/battleCup/${id}/statistics` },
  ];

  const getUserCurrentTournament = () => {
    API.request({
      ...API_ROUTER.tournaments.getUserCurrentTournament,
      pathKeys: {
        userId: userData?.id,
        tournamentId: tournament?.id,
      },
    })
      .then((res) => {
        setIsUserDataParicipant(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMyData();
    getUserGames();
  }, []);
  useEffect(() => {
    checkDetails();
  }, [tournament, userData]);
  useEffect(() => {
    if (userData?.id && tournament?.id) {
      getUserCurrentTournament();
    }
  }, [userData?.id, tournament?.id]);

  return (
    <>
      <StyledTop>
        <StyledTopBanner
          bg={
            tournament?.media?.pageHeaderBanner
              ? tournament?.media?.pageHeaderBanner
              : banerAsia
          }
        >
          <div className="body">
            <div />
            {/*<h1>{tournament?.name || "Tournament"}</h1>*/}
            <img
              style={{ width: "40px", height: "40px", borderRadius: "10px" }}
              src={
                tournament?.media?.logo ? tournament?.media?.logo : banerAsia
              }
              alt="logo"
              className="c-p"
            />
          </div>
        </StyledTopBanner>
        <StyledNews className={classNames("cupTopBg")}>
          <div>
            <h2>{tournament?.name ? tournament?.name : false}</h2> <br />{" "}
            <h3>{tournament?.layer ? tournament?.layer : false}</h3>
            {tournament?.startedAt && tournament?.endedAt
              ? convertDate(tournament?.startedAt) +
                " - " +
                convertDate(tournament?.endedAt)
              : false}{" "}
            {tournament?.tournamentType ? tournament?.tournamentType : false}
            {tournament?.location ? " | " + tournament?.location : false}
            {!isAuthenticated() && (
              <h3 style={{ marginTop: "10px", color: "red" }}>
                <FormattedMessage id="navigation.login" />
              </h3>
            )}
          </div>

          <Button active type="cuptop" status={tournament?.status}>
            <FormattedMessage id="game.tournaments.table.status" />

            <p style={{ fontSize: "12px", color: "black" }}>
              {tournament?.status || "Not actual"}
            </p>
          </Button>
        </StyledNews>
        <StyledButton>
          <article className="events__buttons-and-static">
            {!!tournament?.event && (
              <div
                className="events__button"
                onClick={() => goToUrl(`/eventPage/${tournament?.event?.id}`)}
              >
                <h1 className="events__button-text">
                  <FormattedMessage id="createTournament.title.titleEvent" />
                </h1>
              </div>
            )}

            {isAuthenticated() ? (
              requestSuccess ? (
                isUserDataParicipant?.team ||
                isUserDataParicipant?.teamRoster ||
                isUserDataParicipant?.user ? (
                  <section className="btn__wrapper">
                    <div>
                      <Button color="yellow" type="cupbottom">
                        You already registered
                      </Button>
                    </div>
                  </section>
                ) : tournament?.status === "registration" ? (
                  <section className="btn__wrapper">
                    {hasUserGame() ? (
                      userData?.profileInfoFilled ? (
                        errorMessage ? (
                          <div
                            onClick={() => showErrorToast()}
                            /*  style={{ textDecoration: "none" }} */
                            /* to={props.participationTypeData === 'user' ? `/battleCup/${tournament?.id}/registrationTournament` : false} */
                          >
                            <Button active color="yellow" type="cupbottom">
                              <FormattedMessage id="battlecup.information.registrationButton" />{" "}
                              *
                            </Button>
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              checkParticipationTypeData(
                                tournament.participationType,
                                goToUrl
                              )
                            }
                            /*  style={{ textDecoration: "none" }} */
                            /* to={props.participationTypeData === 'user' ? `/battleCup/${tournament?.id}/registrationTournament` : false} */
                          >
                            <Button active color="yellow" type="cupbottom">
                              <FormattedMessage id="battlecup.information.registrationButton" />
                            </Button>
                          </div>
                        )
                      ) : (
                        <div
                          onClick={() => goToUrl("/profile/settings")}
                          /*  style={{ textDecoration: "none" }} */
                          /* to={props.participationTypeData === 'user' ? `/battleCup/${tournament?.id}/registrationTournament` : false} */
                        >
                          <Button active color="yellow" type="cupbottom">
                            <FormattedMessage id="battlecup.information.fillProfile" />
                          </Button>
                        </div>
                      )
                    ) : !hasUserGame() ? (
                      <div>
                        <div
                          onClick={() =>
                            setOpenMoreInfoModal(!openMoreInfoModal)
                          }

                          // onClick={() => goToUrl("/games")}
                          /*  style={{ textDecoration: "none" }} */
                          /* to={props.participationTypeData === 'user' ? `/battleCup/${tournament?.id}/registrationTournament` : false} */
                        >
                          <Button active color="red" type="cupbottom">
                            <FormattedMessage id="settings.games.addGame" />
                          </Button>
                        </div>
                        {!!openMoreInfoModal && (
                          <div className="addGame">
                            <input
                              icon="pencil"
                              name="nickname"
                              placeholder="In Game Nickname"
                              className="addGameInput"
                              onChange={(e) => setGameNickname(e.target.value)}
                              value={gameNickname}
                            />
                            <button
                              className="addGameInputButton"
                              onClick={() => addGame()}
                            >
                              Add
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      false
                    )}
                  </section>
                ) : (
                  false
                )
              ) : (
                <CircularProgress />
              )
            ) : (
              <div onClick={() => goToUrl("/login")}>
                <Button active color="red" type="cupbottom">
                  <FormattedMessage id="navigation.login" />
                </Button>
              </div>
            )}
          </article>
        </StyledButton>
        <CupTab navList={navList(id)} />
        {!!tournamentDoesnotExistModal && (
          <TournamentDoesnotExistModal
            tournamentDoesnotExistModal={tournamentDoesnotExistModal}
            userUrl={userData?.url}
          />
        )}
      </StyledTop>
    </>
  );
}

export default withRouter(Top);

const StyledTop = styled.div``;

const StyledTopBanner = styled.div`
  background: ${({ bg }) => `url(${bg}) no-repeat center top`};
  background-color: var(--footer-back);
  background-size: cover;
  max-width: 900px;
  min-height: 300px;
  margin: 0 auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  & > .left__logo {
    img {
      position: absolute;
      top: 18px;
      left: 18px;
    }
  }

  & > .body {
    width: 100%;

    padding: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    & > h1 {
      text-transform: uppercase;
      font-size: 26px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: -1.01px;
      line-height: normal;
    }
  }
`;
const StyledButton = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px;

  .events__buttons-and-static {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .events__button {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 10px;
    margin: 10px;
    border-radius: 15px;
    cursor: pointer;
    width: 100px;
    align-items: center;
    border: 1px solid white;
    text-transform: uppercase;
  }
  .events__button-text {
    font-size: 14px;
    padding-right: 10px;
  }
  .addGame {
    position: relative;
    display: inline-block;
  }
  .addGameInput {
    color: black;
    margin-top: 15px;
    padding: 15px 0px 15px 10px;
  }
  .addGame::after {
    content: " ";
    position: absolute;
    bottom: 70%;
    left: 30%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent #f3ab21 transparent;
  }
  .addGameInputButton {
    cursor: pointer;
    margin-left: 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid black;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: 10px 25px;
    white-space: nowrap;
    background: #f6a020;
  }
`;
const StyledNews = styled.div`
  max-width: 900px;
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px;

  & > p {
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: -0.59px;
    line-height: normal;
  }
`;
