import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { API, API_ROUTER } from "../../api";
import PersonalInfo from "./settings";

import Wrapper from "../../components/wrapper";
import { Modal, Button, Container } from "../../components/UI";

import modalLogo from "../../assets/images/passport-logo/passport-logo-clean.png";
import styles from "./styles.module.scss";

const Settings = (props) => {
  const userData = useSelector((state) => state.userData);
  let history = useHistory();

  if (!userData) return false;

  const emailActivated = userData?.emailActivated;

  function activateAccount() {
    API.request({
      ...API_ROUTER.user.activateAccount,
    })
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => console.log(err));
  }

  return (
    <Container className={classNames("headerBg")}>
      <PersonalInfo {...props} />
      {!emailActivated && (
        <Modal
          className={styles.modal__wrapper}
          isTransparent={true}
          open={true}
        >
          <div className={classNames("modalBg", styles.modal)}>
            <img
              className={classNames("modalLogo", styles.modal__image)}
              alt="modal__image"
            />

            <div className={styles.modal__title}>
              <div className={styles.modal__title__header}>
                <FormattedMessage id="settings.modal.hello" />
              </div>
              <div className={styles.modal__title__header}>
                <FormattedMessage id="settings.modal.message" />
              </div>
              <div className={styles.modal__title__buttons}>
                <Button
                  label={
                    <FormattedMessage id="global.forms.labels.send.email" />
                  }
                  type="button"
                  color="white"
                  active
                  size="sm"
                  variant="changePassword"
                  action={() => activateAccount()}
                />
                <p onClick={history.goBack}>
                  {" "}
                  <FormattedMessage id="settings.modal.back" />
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Container>
  );
};

export default Settings;
