import React, { useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import { API, API_ROUTER } from "../../api";

import defaultAvatar from "../../assets/avatars/avatar.jpg";
import loupe from "../../assets/icons/search.svg";
import deleteIcon from "../../assets/icons/delete.png";

import s from "./memberItem.module.scss";

const MemberItem = (props) => {
  const history = useHistory();

  const [activeMenu, setActiveMenu] = useState(false);

  const changePage = () => {
    history.push(`/id/${props.url}`);
  };

  const deleteTeamMember = (teamId, userId) => {
    API.request({
      ...API_ROUTER.teams.delUser,
      pathKeys: {
        teamId: teamId,
        userId: userId,
      },
    })
      .then((res) => {
        props.getMembers(props.teamId);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className={s.playerContainer}>
        <div
          className={s.indicator}
          onClick={() => deleteTeamMember(props.teamId, props.id)}
        >
          <img src={deleteIcon} alt="loupe" />
        </div>
        {activeMenu && (
          <div className={s.contextMenuContainer}>
            <div className={s.imgContainer} onClick={changePage}>
              <img src={loupe} alt="loupe" />
            </div>
            {props.ownerTeam && (
              <div
                className={s.imgContainer}
                onClick={() => deleteTeamMember(props.teamId, props.id)}
              >
                <img src={deleteIcon} alt="loupe" />
              </div>
            )}
          </div>
        )}
        <div
          className={classNames("contentBoxColor", s.userContainer)}
          // onClick={() => setActiveMenu(!activeMenu)}
          onClick={changePage}
        >
          <div className={s.imgContainer}>
            <img src={props.avatar || defaultAvatar} alt="avatar" />
          </div>
          <div className={s.contentBlock}>
            {props.nickname}
            <br />
            <div className={s.titleRole}>
              {props.gamerole === "capitanTeam" ? "Captain" : "Player"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MemberItem;
