import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";

import { isAuthenticated } from "../../../../helpers";
import { API, API_ROUTER } from "../../../../api";

import TournamentItem from "../../../../components/list-items/components/tournamentItem";
import EventItem from "../../../../components/list-items/components/eventItem";

import styles from "./styles.module.scss";

// стили пагинации
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
    },
  },
}));

const CommunityFooter = () => {
  let { tab } = useParams();
  let { communityId } = useParams();
  // let history = useHistory();
  const classes = useStyles();

  const [tournaments, setTournaments] = useState();
  const [events, setEvents] = useState(null);
  const [currentTab, setCurrentTab] = useState(tab);
  const [paginationEvents, setPaginationEvents] = useState(1);
  const [paginationTournaments, setPaginationTournaments] = useState(1);
  const [pageEvents, setPageEvents] = useState(1);
  const [pageTournaments, setPageTournaments] = useState(1);
  const [requestSuccessEvent, setRequestSuccessEvent] = useState(false);
  const [requestSuccessTournament, setRequestSuccessTournament] =
    useState(false);

  const TABS = {
    events: "events",
    tournaments: "tournaments",
  };
  // const CALENDAR_ROUTES = {
  //   events: `/community/${communityId}/events`,
  //   tournaments: `/community/${communityId}/tournaments`,
  // };

  useEffect(() => {
    isAuthenticated()
      ? API.request({
          ...API_ROUTER.community.getCommunityEventAuth,
          pathKeys: {
            communityId: communityId,
            page: pageEvents ? pageEvents : 1,
            limit: 10,
          },
        })
          .then((res) => {
            setPaginationEvents(res.paginator);
            setEvents(res.events);
            setRequestSuccessEvent(true);
          })
          .catch((err) => console.log(err))
      : API.request({
          ...API_ROUTER.community.getCommunityEvent,
          pathKeys: {
            communityId: communityId,
            page: pageEvents ? pageEvents : 1,
            limit: 10,
          },
        })
          .then((res) => {
            setPaginationEvents(res.paginator);
            setEvents(res.events);
            setRequestSuccessEvent(true);
          })
          .catch((err) => console.log(err));
  }, [pageEvents]);

  useEffect(() => {
    isAuthenticated()
      ? API.request({
          ...API_ROUTER.community.getCommunityTournamentsAuth,
          pathKeys: {
            communityId: communityId,
            page: pageTournaments ? pageTournaments : 1,
            limit: 10,
          },
        })
          .then((res) => {
            setPaginationTournaments(res.paginator);
            setTournaments(res.tournaments);
            setRequestSuccessTournament(true);
          })
          .catch((err) => console.log(err))
      : API.request({
          ...API_ROUTER.community.getCommunityTournaments,
          pathKeys: {
            communityId: communityId,
            page: pageTournaments ? pageTournaments : 1,
            limit: 10,
          },
        })
          .then((res) => {
            setPaginationTournaments(res.paginator);
            setTournaments(res.tournaments);
            setRequestSuccessTournament(true);
          })
          .catch((err) => console.log(err));
  }, [pageTournaments]);

  const handleChangeEvents = (event, value) => {
    // подставка значений текущей страницы пагинации в useEffect
    setPageEvents(value);
  };

  const handleChangeTournaments = (event, value) => {
    setPageTournaments(value);
  };

  const renderTournaments = (tournaments) => {
    if (!tournaments.length)
      return (
        <div>
          <FormattedMessage id="community.tournaments.noMore" />
        </div>
      );
    return (
      <article>
        <ul className={styles.event__list__events}>
          {tournaments.map((items) => (
            <TournamentItem {...items} key={items.uuid} />
          ))}
          {(tournaments?.length > 9) | paginationTournaments?.hasNextPage ? (
            <div className={styles.notification__pagination}>
              <Pagination
                classes={{ ul: classes.ul }}
                count={paginationTournaments?.lastPage}
                page={paginationTournaments?.currentPage}
                onChange={handleChangeTournaments}
                variant="outlined"
              />
            </div>
          ) : (
            false
          )}
        </ul>
      </article>
    );
  };
  const renderEvents = (events) => {
    if (!events?.length) return <div>no events</div>;
    return (
      <ul className={styles.event__list__events}>
        {events.map((items) => (
          <EventItem {...items} key={items.uuid} />
        ))}
        {(events?.length > 9) | paginationEvents?.hasNextPage ? (
          <div className={styles.notification__pagination}>
            <Pagination
              classes={{ ul: classes.ul }}
              count={paginationEvents?.lastPage}
              page={paginationEvents?.currentPage}
              onChange={handleChangeEvents}
              variant="outlined"
            />
          </div>
        ) : (
          false
        )}
      </ul>
    );
  };
  const tabChecker = (tabName) => currentTab === tabName;
  const setTabClassNAme = (tabName) =>
    tabChecker(tabName) ? classNames(styles.tab, [styles.active]) : styles.tab;
  return (
    <section className={styles.events__tournaments}>
      <div className={styles.tab__wrapper}>
        <h3
          className={setTabClassNAme(TABS.events)}
          onClick={() => {
            // history.push(CALENDAR_ROUTES.events);
            setCurrentTab(TABS.events);
          }}
        >
          <FormattedMessage id="calendar.events" />
        </h3>

        <h3
          className={setTabClassNAme(TABS.tournaments)}
          onClick={() => {
            // history.push(CALENDAR_ROUTES.tournaments);
            setCurrentTab(TABS.tournaments);
          }}
        >
          <FormattedMessage id="calendar.tournaments" />
        </h3>
      </div>
      {tabChecker(TABS.events) && (
        <section className={styles.events__cards}>
          {requestSuccessEvent ? renderEvents(events) : <LinearProgress />}{" "}
        </section>
      )}
      {tabChecker(TABS.tournaments) && (
        <section className={styles.events__cards}>
          {requestSuccessTournament ? (
            renderTournaments(tournaments)
          ) : (
            <LinearProgress />
          )}
        </section>
      )}
    </section>
  );
};

export default CommunityFooter;
