import React, { useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.scss";
import s from "../styles.module.scss";

export const LowerRound = React.forwardRef(
  (
    {
      data,
      tournamentMember,
      nextMatchId,
      nextLoserMatchId,
      setMatchId,
      setMatchItem,
      setOpenBracketModal,
      openBracketModal,
    },
    ref
  ) => {
    const history = useHistory();
    const getClassRow = (selectRow, selectRowIndex) => {
      if (!selectRow[selectRowIndex - 1]) {
        return styles.rowCenter;
      }

      if (
        selectRowIndex &&
        selectRow[selectRowIndex - 1]?.rows?.length ===
          selectRow[selectRowIndex]?.rows?.length * 2
      ) {
        return styles.roundRow;
      }

      if (
        selectRowIndex &&
        selectRow[selectRowIndex - 1]?.rows?.length / 2 > 3
      ) {
        return styles.rowCenter;
      }

      return styles.rowCenter;
    };

    const getArrayFours = (arr) => {
      const newArr = (arr || []).reduce(function (result, value, index, array) {
        if (index % 4 === 0) result.push(array.slice(index, index + 4));
        return result;
      }, []);

      return [...newArr];
    };

    const getArrayPairs = (arr) => {
      const newArr = (arr || []).reduce(function (result, value, index, array) {
        if (index % 2 === 0) result.push(array.slice(index, index + 2));
        return result;
      }, []);

      return newArr;
    };

    const getTeamName = (val) => {
      const id = val?.tournamentMemberId || val?.id;
      if (!id) {
        return "";
      }

      const findTeam = tournamentMember?.find((el) => el?.id === id);

      if (findTeam) {
        return findTeam?.team?.name || findTeam?.user?.nickname;
      }

      return "";
    };

    const getScore = (val) => {
      if (!val.tournamentMemberId) {
        return "-";
      }

      if (Number.isFinite(val?.score)) {
        return val?.score;
      }

      return "-";
    };

    const getClassScore = (score, arr) => {
      let maxValue = (arr || []).reduce((sum, current) =>
        sum?.score < current ? current : sum?.score
      );

      if (!score) {
        return "playerPontBg";
      }

      if (score === maxValue) {
        return styles.playerOrange;
      }

      return "";
    };
    const getClassScoreNew = (el, block) => {
      let maxScore = Math.max(el[0]?.score, el[1]?.score);
      if (!el) {
        return "playerPontBg";
      }
      if (el[0]?.score === el[1]?.score) {
        return "playerPontBg";
      }

      if (block?.score === maxScore) {
        return s.playerPont_orange;
      }

      return "playerPontBg";
    };

    return (
      <div className={styles.lowerContainer}>
        {data?.cols?.map((item, indexCols) => (
          <>
            <div
              key={`${item.roundName}-${indexCols}`}
              className={styles.roundWrapper}
            >
              <div className={styles.roundTitle}>{item?.roundName}</div>
              <div className={getClassRow(data?.cols, indexCols)}>
                {getArrayFours(item?.rows)?.map(
                  (tournament, indexTournament) => (
                    <div
                      className={classNames("teamsBlockBordersAndBg", {
                        [styles.teamsBlock]: item?.rows.length > 2,
                        [styles.teamsBlockOne]: item?.rows.length <= 2,
                        [styles.teamsBlockWithGroup]: indexCols % 2 === 0,
                      })}
                      key={`item-teams-${indexTournament}`}
                    >
                      {getArrayPairs(tournament)?.map((el, indexEl) => (
                        <div
                          onClick={(e) => setMatchId(setMatchItem(el[0]))}
                          key={`el-index-${indexEl}`}
                          className={classNames(
                            "teamBlockBg",
                            "teamBlockBgLowerRound",
                            el.length > 1
                              ? styles.teamBlock
                              : styles.teamBlockOne
                          )}
                        >
                          <div className={styles.borderArrow} />
                          {el?.map((block, iBlock) => (
                            <div key={`pairs-item-${block?.id}-${iBlock}`}>
                              <div className={s.matchNumber}>{block?.id}</div>
                              <div
                                ref={
                                  nextMatchId === el[0]?.matchId
                                    ? ref
                                    : nextLoserMatchId === el[0]?.matchId
                                    ? ref
                                    : null
                                }
                                style={
                                  nextMatchId === el[0]?.matchId
                                    ? {
                                        backgroundColor: "#755f33",
                                      }
                                    : nextLoserMatchId === el[0]?.matchId
                                    ? {
                                        backgroundColor: "#755f33",
                                      }
                                    : {}
                                }
                                className={classNames(
                                  "playerInfoBgAndBorder",
                                  s.player
                                )}
                                onClick={() =>
                                  setOpenBracketModal(!openBracketModal)
                                }
                              >
                                <span className={s.playerTitle}>
                                  {getTeamName(block)}
                                </span>
                                <div
                                  className={classNames(
                                    s.playerPont,
                                    getClassScoreNew(el, block)
                                  )}
                                >
                                  {getScore(block)}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        ))}
      </div>
    );
  }
);

export default LowerRound;
