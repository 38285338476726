import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import defaultLogo from "../../assets/avatars/avatar.jpg";
import getTeamMembers from "../../helpers/team/teamMembers";
import PlayersContainer from "../../components/players-container";

import s from "./playersContainer.module.scss";

const TeamContainer = (props) => {
  const [btnCheck, setBtnCheck] = useState(false);
  const [teamId, setTeamId] = useState();
  const [gameId, setGameId] = useState();

  const [player, setPlayer] = useState();
  const [requestSuccess, setRequestSuccess] = useState(false);
  useEffect(() => {
    if (teamId === undefined) {
      return;
    }

    getTeamMembers(teamId, gameId).then((res) => {
      setPlayer(res.members);
      setRequestSuccess(true);
    });
  }, [teamId]);
  const changeCountPlayers = (boolean, id, name, gameId) => {
    setBtnCheck(!boolean);
    setTeamId(id);
    setGameId(gameId);
    props.setTeamId(id);
    props.setTeamName(name);
    // reset count if close team
    if (btnCheck === true) {
      props.setCountPlayers(0);
      props.setTeamRosterMembers([]);
    }
    props.setCurrentTeam([props.el]);
    props.setFlag(boolean);
    // props.setUserTeams([props?.el]);
    // props.setCountPlayers(props.countPlayers - 1);
    // const newArray = props.teamRosterMembers.filter((el) => el.userId !== id);
    // console.log(newArray);
    // props.setTeamRosterMembers(newArray);
    // else {
    //   props.setCountPlayers(props.countPlayers + 1);
    //   const newObj = {};
    //   newObj.userId = id;
    //   newObj.nickname = name;
    //   newObj.role = role;

    //   const newArray = JSON.parse(JSON.stringify(props.teamRosterMembers));
    //   newArray.push(newObj);
    //   props.setTeamRosterMembers(newArray);
    // }
  };
  const history = useHistory();
  function goToUrl(url) {
    history.push(url);
  }

  return (
    <>
      <div className={s.playersContainer}>
        <div className={s.playerCard}>
          <div
            className={s.leftContainer}
            onClick={() => {
              goToUrl(`/teams/team/${props.id}`);
            }}
          >
            <div className={s.imgContainer}>
              <img src={props.logo || defaultLogo} alt="logo" />
            </div>
            <div className={s.textContainer}>
              <div className={s.teamName}>{props.name}</div>
            </div>
          </div>
          {props.btn ? (
            <div
              className={
                btnCheck
                  ? classNames("checkMarkBg", s.checkMark, s.btn)
                  : classNames("plusButtonBg", s.plus, s.btn)
              }
              onClick={() => {
                changeCountPlayers(
                  btnCheck,
                  props.id,
                  props.name,
                  props.gameId
                );
              }}
            ></div>
          ) : (
            <div className={s.plusMini}>+</div>
          )}
        </div>
      </div>

      {btnCheck ? (
        requestSuccess ? (
          <>
            <div
              className={classNames(
                "registerTournamentPlayersRowBg",
                s.playerRow
              )}
            >
              <div className={s.rowTitle}>
                <FormattedMessage id="registration.tournament.members" />
              </div>
            </div>
            {player?.map((el) => (
              <PlayersContainer
                key={el.id}
                id={el.id}
                url={el.url}
                role={el.role}
                gamerole={el.gamerole}
                nickname={el.nickname}
                gameNickname={el.gameNickname}
                avatar={el.avatar}
                gender={el.gender}
                age={el.age}
                country={el.country}
                profileInfoFilled={el.profileInfoFilled}
                btn={true}
                setCountPlayers={props.setCountPlayers}
                countPlayers={props.countPlayers}
                setTeamRosterMembers={props.setTeamRosterMembers}
                teamRosterMembers={props.teamRosterMembers}
                detailsGenders={props.gender}
                detailsAge={props.minimumAge}
                detailsCountry={props.countries}
              />
            ))}
          </>
        ) : (
          <CircularProgress />
        )
      ) : (
        false
      )}
    </>
  );
};
export default TeamContainer;
