import React, { useState } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

//
import Card from "./Card";

export default function Row({
  title = "Title",
  cards = [],
  history,
  teamId,
  teamRolesOwner,
  teamRolesMember,
  setTeamFilter,
  requestSuccess,
  teamProgression,
}) {
  const [selected, setSelected] = useState("owner");

  return (
    <StyledRow>
      <section>
        <h1>{title}</h1>
        <p
          style={
            selected === "owner" ? { color: "orange" } : { color: "white" }
          }
          onClick={() => {
            setTeamFilter("owner");
            setSelected("owner");
          }}
        >
          {teamRolesOwner}
        </p>
        <p
          style={
            selected === "member" ? { color: "orange" } : { color: "white" }
          }
          onClick={() => {
            setTeamFilter("member");
            setSelected("member");
          }}
        >
          {" "}
          {teamRolesMember}
        </p>
      </section>
      <section>
        {requestSuccess || teamProgression ? (
          cards.length ? (
            cards.map((card) => (
              <Card {...card} key={card.id} history={history} teamId={teamId} />
            ))
          ) : (
            <div className="empty-data">There are no games yet</div>
          )
        ) : (
          <CircularProgress />
        )}
      </section>
    </StyledRow>
  );
}

const StyledRow = styled.div`
  ${"" /* background-color: #3f317c; */}
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
  & > section {
    &:first-of-type {
      padding: 10px 23px;
      ${"" /* box-shadow: 0 12px 13px 0px #00000075; */}
      z-index: 2;
      ${"" /* background-color: var(--bg); */}
      background: rgba(255, 255, 255, 0.05);
      display: flex;

      & > h1 {
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: -0.67px;
        line-height: normal;
        margin: 0;
      }
      & > p {
        font-size: 17px;
        font-style: normal;
        letter-spacing: -0.67px;
        line-height: normal;
        text-decoration: underline;
        padding-left: 25px;
        cursor: pointer;
      }
    }

    &:last-of-type {
      padding: 18px 31px 18px;
      display: flex;
      gap: 50px;
      overflow-y: auto;
      overflow-x: auto;
      white-space: nowrap;
      min-height: 150px;
      background: rgba(255, 255, 255, 0.05);
      margin-top: 10px;
    }
    ::-webkit-scrollbar {
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #818b99;
      border: 3px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }
  }
`;
