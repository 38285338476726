import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";

import { ROUTER, HIDE_FOR } from "../../config";
import { isAuthenticated } from "../../helpers";

import Login from "../../pages/login"; // new
import ResetPassword from "../../pages/reset-passwod"; // new
import newPassword from "../../pages/new-password"; // new
import ConfirmPassword from "../../pages/confirm-password"; // new
import Profile from "../../pages/profile"; // new
import AddRoster from "../../pages/add-roster"; // new
import SignUp from "../../pages/signup"; // new
import TermsOfUse from "../../pages/terms-of-use"; // new
import CookiePolicy from "../../pages/cookie-policy"; // new
import PrivacyPolicy from "../../pages/privacy-policy"; // new
import Rulebok from "../../pages/rulebook-asia"; // new
import ProfileMatches from "../../pages/profile-matches"; // new
import CommunityPage from "../../pages/community-page/index"; // new
import EventPage from "../../pages/event-page"; //new
import Leaderboard from "../../pages/rating/rating"; //new
import Roster from "../../pages/roster/roster"; //new
import disciplineRoster from "../../pages/roster/discipline"; //new
import CreateRoster from "../../pages/roster/createRoster"; //new
import searchTournaments from "../../pages/matches"; // new
import Settings from "../../pages/profile-settings"; // new
import TeamSettings from "../../pages/team-create-change/edit-team"; // new
import CreateTeam from "../../pages/team-create-change/create/create-team"; // new
import EmailConfirm from "../../pages/email-confirm"; // new
import PageNotFound from "../../pages/page-not-found"; // new
import NeedAuth from "../../pages/need-auth"; // new
import SupportContainer from "../../pages/support"; // new
import Team from "../../pages/team"; // new
import InviteTeam from "../../pages/inviteTeam"; // new
import ChangePassword from "../../pages/change-password"; // new
import More from "../../pages/more"; // new
import Calendar from "../../pages/calendar"; // new
import Notifications from "../../pages/notifications"; // new
import CupBracket from "../../pages/battle-cup/HOC/CupBracketHOC"; //new
import CupInformation from "../../pages/battle-cup/HOC/CupInformationHoc"; //new
import CupMatches from "../../pages/battle-cup/HOC/CupMatchesHOC"; //new
import CupParticipants from "../../pages/battle-cup/HOC/CupParticipantsHOC"; //new
import CupRules from "../../pages/battle-cup/HOC/CupRulesHOC"; //new
import Games from "../../pages/games/Games"; //new
import GamesRoster from "../../pages/games"; // new
import MatchDiscussion from "../../pages/match-discussion"; //new
import RegistrationTournament from "../../pages/registration-tournament"; // new
import Followed from "../../pages/followed";
import AcceptInvite from "../../pages/accept-invite";
import GameWiki from "../../pages/game-wiki-page";

const StyledMain = styled.main`
  flex: 1;
`;

const PrivateRoute = ({ children, ...rest }) => {
  if (!isAuthenticated()) {
    return <NeedAuth />;
  }

  return <Route {...rest} />;
};

export const Context = React.createContext();

const Main = () => {
  const [location, setLocation] = useState(window.location.pathname);

  useEffect(() => {
    setLocation(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <StyledMain
      style={
        HIDE_FOR.includes(location)
          ? {}
          : { marginBottom: "var(--menu-height)" }
      }
    >
      <Switch>
        <Route path={ROUTER.homepage} exact component={Login} />
        <Route path={ROUTER.login} exact component={Login} />
        <Route path={ROUTER.signup} exact component={SignUp} />
        <Route path={ROUTER.needauth} exact component={NeedAuth} />
        <Route
          path={ROUTER.confirmPassword}
          exact
          component={ConfirmPassword}
        />

        <Route path={ROUTER.resetPassword} exact component={ResetPassword} />
        <Route path={ROUTER.changePassword} exact component={newPassword} />
        <Route path={ROUTER.emailConfirm} exact component={EmailConfirm} />
        <PrivateRoute path={ROUTER.addroster} exact component={AddRoster} />
        <Route path={ROUTER.watch} exact component={searchTournaments} />
        <Route path={ROUTER.termsOfUse} exact component={TermsOfUse} />
        <Route path={ROUTER.privacyPolicy} exact component={PrivacyPolicy} />
        <Route path={ROUTER.cookiePolicy} exact component={CookiePolicy} />
        <Route path={ROUTER.eventPage} exact component={EventPage} />
        <PrivateRoute path={ROUTER.games.gamesList} exact component={Games} />
        <PrivateRoute
          path={ROUTER.createRoster}
          exact
          component={CreateRoster}
        />
        <PrivateRoute path={ROUTER.followed} exact component={Followed} />
        <PrivateRoute path={ROUTER.gamesRoster} exact component={GamesRoster} />
        <PrivateRoute path={ROUTER.roster} exact component={Roster} />
        <PrivateRoute
          path={ROUTER.disciplineRoster}
          exact
          component={disciplineRoster}
        />
        {/* <PrivateRoute
          path={ROUTER.profileMatches}
          exact
          component={ProfileMatches}
        /> */}

        <Route
          path={ROUTER.community.communityTabs}
          exact
          component={CommunityPage}
        />
        <PrivateRoute
          path={ROUTER.battleCup.registrationTournament}
          exact
          component={RegistrationTournament}
        />
        {/* <PrivateRoute path={ROUTER.teams.invite} exact component={TeamInvite} /> */}
        <Route
          path={ROUTER.battleCup.bracket.preliminary_tournament}
          exact
          component={CupBracket}
          /* preliminary_tournament */
        />
        <Route
          path={ROUTER.battleCup.bracket.group_stage}
          exact
          component={CupBracket}
          /* group_stage */
        />
        <Route
          path={ROUTER.battleCup.bracket.play_off}
          exact
          component={CupBracket}
          /* play_off */
        />
        <Route path={ROUTER.battleCup.rules} exact component={CupRules} />
        <Route
          path={ROUTER.battleCup.information}
          exact
          component={CupInformation}
        />
        <Route path={ROUTER.battleCup.matches} exact component={CupMatches} />
        <Route
          path={ROUTER.battleCup.participants}
          exact
          component={CupParticipants}
        />

        <Route path={ROUTER.games.wiki} exact component={GameWiki} />

        <PrivateRoute path={ROUTER.more} exact component={More} />
        <Route path={ROUTER.home} exact component={Profile} />
        <PrivateRoute path={ROUTER.rating} exact component={Leaderboard} />
        <Route path={ROUTER.calendar} exact component={Calendar} />
        <PrivateRoute
          path={ROUTER.notifications}
          exact
          component={Notifications}
        />

        <Route path={ROUTER.id} exact component={Profile} />

        <Route path={ROUTER.rulebook} exact component={Rulebok} />
        <PrivateRoute
          path={ROUTER.profile.settings}
          exact
          component={Settings}
        />

        <Route
          path={ROUTER.MatchDisscusion}
          exact
          component={MatchDiscussion}
        />

        <PrivateRoute path={ROUTER.teams.create} exact component={CreateTeam} />
        <PrivateRoute
          path={ROUTER.teams.edit}
          exact
          component={TeamSettings} //new
        />

        <Route path={ROUTER.teams.team} exact component={Team} />
        <Route path={ROUTER.teams.inviteTeam} exact component={InviteTeam} />
        <Route path={ROUTER.acceptInvite} exact component={AcceptInvite} />

        <PrivateRoute
          path={ROUTER.support}
          exact
          component={SupportContainer}
        />
        <Route path="*" exact component={PageNotFound} />
      </Switch>
    </StyledMain>
  );
};

export default Main;
