import { useEffect } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import s from "./CupBracket.module.scss";
import { useState } from "react";
import { API, API_ROUTER } from "../../api";
import MatchMembersFFA from "./bracketTabs/FFA";
import { Preloader } from "../UI";

export default function CupBracketFFA(props) {
  const { tournament } = props;
  let { id } = useParams();

  const [gridData, setGridData] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [navList, setNavList] = useState([]);
  const [navListMatchesFFA, setNavListMatchesFFA] = useState([]);
  const [roundsFFA, setRoundsFFA] = useState();
  const [matchesFFA, setMatchesFFA] = useState();
  const [matchData, setMatchData] = useState();
  const [activeTabRoundsFFA, setActiveTabRoundsFFA] = useState(false);
  const [activeTabMatchesFFA, setActiveTabMatchesFFA] = useState(false);
  const [isAllowRoundUpdate, setIsAllowRoundUpdate] = useState(false);

  // получаю данные турнира
  const getTournamentData = async (tournament) => {
    setIsLoad(true);
    if (tournament === undefined) return;
    // пока что проверяем по 0 индексу, потом надо будет переделать будет больше стадий

    return await API.request({
      ...API_ROUTER.tournaments.getTournamentDataFFA,
      pathKeys: {
        id: id,
      },
    })
      .then((res) => {
        setGridData(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoad(false));
  };

  // задаю настройки навигации по табам
  const setNavigationRoundsFFA = () => {
    let ffaRound = [];
    gridData?.basket?.map((el) => {
      el?.cols?.map((el) => {
        ffaRound.push(el);
      });
    });
    setRoundsFFA(ffaRound);
    setNavList(
      ffaRound.map((el) => {
        return {
          id: el?.roundName,
          title: el?.roundName,
        };
      })
    );
  };
  const setNavigationMatchesFFA = () => {
    let ffaMatches = [];
    matchesFFA?.map((el) => {
      ffaMatches.push(el.stageFfa);
    });
    setNavListMatchesFFA(
      ffaMatches.map((el, i) => {
        return {
          id: el?.id,
          title: `match ${i + 1}`,
        };
      })
    );
  };
  // переключение табов
  const goToTabRoundsFFA = (id) => {
    roundsFFA?.map((el) => {
      if (el?.roundName === id) {
        setMatchesFFA(el?.rows);
      }
    });
    setActiveTabRoundsFFA(id);
  };
  const goToTabMatchesFFA = (id) => {
    matchesFFA?.map((el) => {
      if (el?.matchId === id) {
        setMatchData(el);
      }
    });
    setActiveTabMatchesFFA(id);
  };
  /// получаю данные турнира
  useEffect(() => {
    getTournamentData(tournament);
    setIsAllowRoundUpdate(tournament?.isAllowRoundUpdate);
  }, [tournament]);

  // задаю первую вкладку по умолчанию
  useEffect(() => {
    goToTabRoundsFFA(navList[0]?.id);
  }, [roundsFFA]);
  useEffect(() => {
    goToTabMatchesFFA(navListMatchesFFA[0]?.id);
  }, [matchesFFA, navListMatchesFFA]);

  // навигация по табам раундов
  useEffect(() => {
    setNavigationRoundsFFA();
  }, [gridData, tournament?.stages?.length]);
  // навигация по табам матчей
  useEffect(() => {
    setNavigationMatchesFFA();
  }, [matchesFFA]);
  return (
    <div className={s.bracket}>
      {!!isLoad && <Preloader />}

      {!isLoad && (
        <>
          {!!navList.length && (
            <div
              className={classNames("cupTabBorder", "b-shadow", s.container)}
            >
              <ul className={s.list}>
                {navList.map((i) => (
                  <>
                    <li
                      key={`cup-tab-${i.id}`}
                      className={classNames(
                        {
                          [s.active]: i.id === activeTabRoundsFFA,
                        },
                        s.listItem
                      )}
                      onClick={() => goToTabRoundsFFA(i.id)}
                    >
                      <div style={{ width: "70px" }}>{i.title}</div>
                      <div
                        style={{
                          borderBottom:
                            i.id === activeTabRoundsFFA &&
                            "1px solid rgb(247, 160, 29)",
                        }}
                      ></div>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          )}
          {!!navListMatchesFFA.length && (
            <div
              style={{ marginTop: "10px" }}
              className={classNames("cupTabBorder", "b-shadow", s.container)}
            >
              <ul className={s.list}>
                {navListMatchesFFA.map((i) => (
                  <>
                    <li
                      key={`cup-tab-${i.id}`}
                      className={classNames(
                        {
                          [s.active]: i.id === activeTabMatchesFFA,
                        },
                        s.listItem
                      )}
                      onClick={() => goToTabMatchesFFA(i.id)}
                    >
                      <div style={{ width: "70px" }}>{i.title}</div>
                      <div
                        style={{
                          display:
                            i.id === activeTabMatchesFFA ? "block" : "none",
                          borderBottom: "1px solid rgb(247, 160, 29)",
                        }}
                      ></div>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          )}

          {!gridData ||
            (!gridData?.basket?.length && (
              <div className={s.noData}>No data</div>
            ))}
          {!!(gridData && gridData?.basket?.length) && (
            <MatchMembersFFA
              match={matchData}
              isAllowRoundUpdate={isAllowRoundUpdate}
            ></MatchMembersFFA>
          )}
        </>
      )}
    </div>
  );
}
