import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import { gapi } from "gapi-script";
import axios from "axios";
import classNames from "classnames";

import {
  getUserData,
  hideQrModal,
  userOnline as runUserOnline,
} from "../../redux/actions";
import { API, API_ROUTER } from "../../api";
import store from "../../redux/store";

// social auth
// import FacebookLogin from "react-facebook-login";
// import { GoogleLogin } from "react-google-login";
// import ifNeedRedirect from "./constants/index";
// import { GOOGLE_AUTH_CLIENT_ID, FACEBOOK_AUTH_CLIENT_ID } from "../../config";
import { isEmailValid, isFieldEmpty, ifNeedRedirect } from "../../helpers";
import { CheckBox } from "../../components/UI";
import LangSwitcher from "../../components/lang-switcher";
import { Modal } from "../../components/UI";
import Logo from "../../components/logo";
import InputLogin from "../../components/UI/forms/input-logo_register";
import ButtonLogin from "../../components/UI/buttons/buttons-login_register";

import modalLogo from "../../assets/images/passport-logo/passport-logo-clean.png";
// TODO img hogasia
import logo from "../../assets/images/passport-logo.png";
import "../../assets/styles/index.css";
import styles from "./styles.module.scss";

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const userOnline = useSelector((state) => state.userOnline);

  const routeChange = () => {
    let path = `/signup`;
    history.push(path);
  };

  const getDefaultErrors = () => {
    return {
      username: "",
      password: "",
      newSiteRegister: "",
    };
  };
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [ip, setIP] = useState("");
  const [errors, setErrors] = useState(getDefaultErrors);
  const [ifOtherSiteRegistration, setIfOtherSiteRegistration] = useState();
  const [newSiteRegisterChecked, setNewSiteRegisterChecked] = useState(false);
  const [openNewSiteModal, setNewSiteMidal] = useState(false);
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };
  // xxamgad1324@gmail.com Amgad1421
  // social auth нужно для коректной работы гугл формы

  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: GOOGLE_AUTH_CLIENT_ID,
  //       scope: "",
  //     });
  //   };
  //   gapi.load("client:auth2", initClient);
  // });

  useEffect(() => {
    if (userOnline) {
      dispatch(getUserData());

      if (userData) {
        history.push(`/id/${userData?.url}`);
      }
    }
    getData();
  }, [userOnline, userData]);

  const onInputChange = (type) => {
    return (e) => {
      const setter = type === "username" ? setUserName : setPassword;

      setter(e.target.value);
    };
  };
  const actionSignIn = (e) => {
    e.preventDefault();

    // validate
    errors.username = isEmailValid(username);
    errors.password = isFieldEmpty(password);

    setErrors({ ...errors });

    // send request if valid
    if (!Object.values(errors).some((value) => value.length)) {
      const params = {
        ...API_ROUTER.auth.logIn,
        data: {
          username,
          password,
          newSiteRegister: newSiteRegisterChecked,
          sourceUrl: "https://app.passport.gg/",
        },
      };

      API.request(params, true)
        .then((res) => {
          const { token } = res;

          if (token) {
            localStorage.setItem("token", token);

            dispatch(getUserData());
            dispatch(runUserOnline());

            toast.success("successfully logged in");
            history.push(ifNeedRedirect());
          }
        })
        .catch((err) => {
          if (err?.data && err?.data?.message) {
            toast.error(err?.data && err?.data?.message);
          }
          if (err?.data?.message === "Need to register on this site") {
            setIfOtherSiteRegistration(err?.data?.errors);
            setNewSiteMidal(true);
          }
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      actionSignIn();
    }
  };

  // auth social
  // const responseThirdAuth = (api, response) => {
  //   const { history } = props;

  //   const params = {
  //     ...api,
  //     data: {
  //       token: response.accessToken,
  //       ip: ip,
  //       sourceUrl: "https://app.passport.gg/",
  //     },
  //   };

  //   API.request(params, true)
  //     .then(({ token }) => {
  //       if (token) {
  //         localStorage.setItem("token", token);

  //         dispatch(getUserData());
  //         dispatch(runUserOnline());

  //         const userData = store.getState().userData;

  //         toast.success("Successfully logged in");
  //         history.push(ifNeedRedirect());
  //       }
  //     })
  //     .catch((err) => {
  //       if (err?.data && err?.data?.message) {
  //         toast.error(err?.data && err?.data?.message);
  //       }
  //     });
  // };

  const onCheckboxChange = (e) => {
    if (e.target.name === "newSiteRegister")
      setNewSiteRegisterChecked(!newSiteRegisterChecked);
  };

  const passwordPlaceholder = useIntl().formatMessage({
    id: "login.input.password",
  });

  const emailPlaceholder = useIntl().formatMessage({
    id: "login.input.email",
  });

  return (
    <div className={classNames("bgGradient", styles.auth)}>
      <div className={styles.auth__container}>
        <Logo />
        <div className={styles.language__switch}>
          <LangSwitcher />
        </div>
        <p className={styles.auth__text}>
          <FormattedMessage id="publicHeader.h1" />
        </p>
        <form
          className={classNames("whiteBorder", styles.auth__form)}
          noValidate
          autoComplete="off"
        >
          <div className={styles.input__group}>
            <InputLogin
              type="email"
              placeholder={emailPlaceholder}
              icon="email-user"
              name="login"
              className={styles.login__input}
              onChange={onInputChange("username")}
              value={username}
              error={errors.username}
              onKeyPress={handleKeyPress}
            />
            <InputLogin
              type="password"
              placeholder={passwordPlaceholder}
              icon="password"
              name="password"
              className={styles.password__input}
              onChange={onInputChange("password")}
              value={password}
              error={errors.password}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className={styles.forgot__password}>
            <Link to={"/password/reset"}>
              <FormattedMessage id="login.forgotPassword" />
            </Link>
          </div>
          <div className={styles.btn__group}>
            <ButtonLogin
              type="submit"
              name="login"
              active={true}
              onClick={(e) => actionSignIn(e)}
            >
              <FormattedMessage id="login.loginUppercase" />
            </ButtonLogin>
          </div>
          <p className={classNames("whiteText", styles.sign__up)}>
            <FormattedMessage id="login.EraAccount" />
            <span
              className={classNames("mainColorfulText")}
              onClick={routeChange}
            >
              <FormattedMessage id="login.signUp" />
            </span>
          </p>

          <div className={styles.support__button}>
            <a
              className={classNames("mainColorfulText", styles.support__button)}
              href="https://about.passport.gg/supportpage"
              target={"_blank"}
            >
              <FormattedMessage id="footer.support" />
            </a>
          </div>
        </form>
        <div className={styles.footer__powerBy}>
          <a
            href="http://app.passport.gg/"
            target={"_blank"}
            style={{ textDecoration: "none" }}
          >
            Powered by &nbsp;&nbsp; &nbsp;&nbsp;
            <img src={logo} width="150px" />
          </a>
        </div>
        {/* TODO add if we need*/}
        {/* <div className="social__auth">
        <p className="social__label">
          {" "}
          <FormattedMessage id="login.loginWith" />
        </p>
        <div className="social__wrapper">
          <div className="google">
            <GoogleLogin
              className="login-with__google"
              clientId={GOOGLE_AUTH_CLIENT_ID}
              buttonText={<i className="icon icon-google" />}
              onSuccess={(resp) =>
                responseThirdAuth(API_ROUTER.auth.googleAuthCheck, resp)
              }
              onFailure={(e) => {
                console.error(e);
              }}
              cookiePolicy={"single_host_origin"}
              icon={false}
            />
          </div>
          <div className="facebook">
            <FacebookLogin
              appId={FACEBOOK_AUTH_CLIENT_ID}
              cssClass="login-with__facebook"
              fields="name,email"
              scope="public_profile"
              callback={(resp) =>
                responseThirdAuth(API_ROUTER.auth.facebookAuthCheck, resp)
              }
              cookie={true}
              textButton={<i className="icon icon-facebook" />}
            />
          </div>
        </div>
      </div> */}
        <Modal
          className={classNames(styles.modal__wrapper)}
          isTransparent={true}
          open={openNewSiteModal}
          onClose={() => {
            dispatch(hideQrModal());
            setNewSiteMidal(false);
          }}
        >
          <div className={classNames("modalBg", styles.modal)}>
            <img
              className={classNames("modalLogo", styles.modal__image)}
              alt="modal__image"
            />

            <div className={styles.modal__title}>
              <div className={styles.modal__title__header}>Hi gamer !</div>
              <div className={styles.modal__title__header}>
                You already have account at
              </div>

              {/* maping show all sites  */}
              <div className={styles.modal__title__link}>
                {ifOtherSiteRegistration?.existSites?.map((el) => (
                  <a href={el} target="_blank">
                    {el}
                  </a>
                ))}
              </div>
              <div className={styles.modal__title__footer}>
                Accept our rules to continue with this account, or make a{" "}
                <a
                  className={styles.modal__title__footer__newAccount}
                  onClick={routeChange}
                >
                  new account
                </a>
              </div>
            </div>
            <CheckBox
              required
              name="newSiteRegister"
              onChange={onCheckboxChange}
              checked={newSiteRegisterChecked}
              error={errors.newSiteRegister}
            >
              <p>
                <Link to={"/terms-of-use"}>
                  {" "}
                  <FormattedMessage id="authModal.signUp.check1.linkTerms" />
                </Link>{" "}
                <Link to={"/privacy-policy"}>
                  {" "}
                  <FormattedMessage id="authModal.signUp.check1.linkPrivacy" />
                </Link>{" "}
                <FormattedMessage id="signup.check.and" />{" "}
                <Link to={"/cookie-policy"}>
                  {" "}
                  <FormattedMessage id="authModal.signUp.check1.linkCookie" />
                </Link>{" "}
              </p>
            </CheckBox>
            <div className={styles.modal__button}>
              <ButtonLogin
                type="submit"
                name="login"
                active={true}
                onClick={actionSignIn}
              >
                <FormattedMessage id="login.loginUppercase" />
              </ButtonLogin>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Login;
