import styled from "styled-components";
import { TitleRow } from "../../../../components/UI/";
import { InnerBox } from "../../../../components/UI";
export const StyledRow = styled.div`
  & > section {
    &:first-of-type {
      padding: 10px 23px;
      ${"" /* box-shadow: 0 12px 13px 0px #00000075; */}
      z-index: 2;
      background: rgba(255, 255, 255, 0.05);
      display: flex;

      & > h1 {
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: -0.67px;
        line-height: normal;
        margin: 0;
        padding-right: 20px;
      }
      & > h2 {
        color: orange;
      }
      & > p {
        font-size: 17px;
        font-style: normal;
        letter-spacing: -0.67px;
        line-height: normal;
        text-decoration: underline;
        padding-left: 40px;
        cursor: pointer;
      }
    }
  }
  .events__games-container {
    padding: 18px 31px 18px;
    display: flex;
    gap: 50px;
    overflow-y: auto;

    min-height: 150px;
  }
`;
const Styled = {
  StyledTitleRow: styled(TitleRow)`
    margin: 0 0 20px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  Tabs: styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin: 6px 0;
    }

    div {
      cursor: pointer;
      transition: all 0.3s ease;
      font-weight: 500;
      font-size: 14px;

      &:hover {
        color: #eda211;
      }
    }

    div.active {
      color: #eda211;
    }

    div + div {
      margin-left: 30px;
    }
  `,
  ProgressRow: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,

  ProgressCol: styled.div`
    padding: 0 5px;
    width: 33.3333%;

    @media (max-width: 767px) {
      width: 100%;

      & + & {
        margin-top: 10px;
      }
    }
  `,
  ProgressTitle: styled.div`
    text-align: center;
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 18px;
  `,
  PerformanceTitle: styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #eda211;
    margin: 0 0 16px;

    @media (max-width: 767px) {
      text-align: center;
    }
  `,
  PerformanceRow: styled.div`
    margin: 0 20px;
    display: flex;
    flex-wrap: wrap;
  `,
  PerformanceCol: styled.div`
    padding: 0 5px;
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;

      & + & {
        margin-top: 5px;
      }
    }
  `,
  PerformanceData: styled.div`
    display: flex;

    & + & {
      margin-top: 5px;
    }

    div {
      border: 1px solid rgba(213, 203, 255, 0.2);
      box-sizing: border-box;
      border-radius: 3px;
      font-weight: 500;
      font-size: 14px;
      padding: 5px 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        width: 100%;
      }

      &:last-child {
        width: 33%;
        flex-shrink: 0;
        margin-left: 5px;
      }
    }

    .green {
      color: #14c911;
    }

    .red {
      color: red;
    }
  `,
  PerformanceControls: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;

    @media (max-width: 767px) {
      flex-direction: column;

      button + button {
        margin-top: 10px;
      }
    }
  `,
  NoTableData: styled.div`
    font-size: 14px;
  `,
  TableHero: styled.div`
    display: flex;
    align-items: center;

    img {
      display: block;
      margin-right: 8px;
    }
  `,
  AddResults: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .copy {
      font-weight: bold;
      font-size: 21px;
      color: #d5cbff;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  `,
  Row: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  `,
  Col: styled.div`
    padding: 0 5px;
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;

      & + & {
        margin-top: 10px;
      }
    }

    &.about {
      width: 100%;
      margin-top: 10px;
    }

    &.social {
      margin-top: 10px;
    }

    &.controls {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button + button {
        margin-left: 12px;
      }

      @media (max-width: 767px) {
        width: 100%;
        flex-direction: column;

        button + button {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  `,
  Content: styled.div`
    .logos {
      display: flex;
      justify-content: center;
      margin: 0 -10px;

      .logo {
        padding: 0 10px;
        width: 33.3333%;
        display: flex;
        justify-content: center;

        img {
          display: block;
          max-width: 100%;
        }
      }
    }

    .qr {
      display: flex;
      justify-content: center;

      img {
        min-width: 100%;
      }
    }

    .nickname {
      text-align: center;
      font-size: 21px;
      font-weight: bold;
      margin: 0 0 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      svg {
        position: relative;
        top: -4px;
        margin-left: 6px;
      }
    }

    .username {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      margin: 0 0 4px;
    }

    .role {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
    }

    .sectionTitle {
      font-weight: 500;
      font-size: 21px;
      margin: 0 0 16px;

      &.next {
        margin: 16px 0;
      }

      &.withSwitcher {
        display: flex;
        align-items: center;
      }
      &.moreInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .moreInfo {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.03em;
        text-decoration-line: underline;
        text-transform: capitalize;
        text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        cursor: pointer;
      }

      strong {
        font-weight: bold;
      }
    }

    .dataRow {
      font-size: 14px;
      display: flex;
      align-items: flex-start;

      @media (min-width: 992px) and (max-width: 1199px) {
        flex-direction: column;
      }

      &.center {
        justify-content: center;
      }

      & + .dataRow {
        margin-top: 6px;
      }

      > div {
        &:first-child {
          min-width: 90px;
          padding-right: 16px;
          flex-shrink: 0;
        }
      }

      &.row-type {
        display: block;
      }
    }
    .unfollow {
        display: flex;
        flex-direction: column;
        align-items: self-end;
     }
    .edit__banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      min-width: 78px;
      min-height: 22px;

      &:hover {
        cursor: pointer;
      }

      & > p {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: -0.47px;
        line-height: normal;
        text-decoration: underline;
        margin-top: 3px;
      }
  `,
  StyledInnerBox: styled(InnerBox)`
    height: 100%;
  `,
  AvatarHolder: styled.div`
    width: 118px;
    height: 118px;
    margin: 0 auto 16px;
    position: relative;
  `,
  Avatar: styled.div`
    width: 118px;
    height: 118px;
    border-radius: 10px;
    overflow: hidden;
    background: ${(props) =>
      props.image
        ? `#eee url(${props.image}) no-repeat center center`
        : "#eee"};
    background-size: cover;
    border: ${(props) => !!props.pro && "2px solid gold"};
  `,
  About: styled.div`
    font-size: 14px;
    padding-left: 30px;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  `,
};

export default Styled;
