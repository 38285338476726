import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useDraggable } from "react-use-draggable-scroll";
import moment from "moment";
import { InputLabel, MenuItem, Select, Button } from "@material-ui/core";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";

import { isAuthenticated } from "../../../../helpers";
import { Preloader, DateInput } from "../../../UI";
import { API, API_ROUTER } from "../../../../api";

import noImage from "../../../../assets/images/no-image.webp";

import styles from "./styles.module.scss";
const TransModal = styled(Dialog)`
  .MuiPaper-root {
    overflow: visible;
    border-radius: 0;
    background-color: transparent;
  }
`;
const useStyles = makeStyles((theme) => ({
  paper: {
    background: "#707070",
  },
}));
export const MatchMembersFFA = ({ match, isAllowRoundUpdate }) => {
  const history = useHistory();
  const dragRef = useRef();
  const classes = useStyles();
  const { events } = useDraggable(dragRef);

  const [openRoundChange, setOpenRoundChange] = useState(false);
  const [inputTime, setInputTime] = useState();
  const [inputDate, setInputDate] = useState();
  const [roundId, setRoudnId] = useState();

  const [roundBestOf, setRoundBestOf] = useState("");
  const [membersState, setMembersState] = useState([]);
  // если участник - команда, то выводим название команды, если нет, то никнейм
  const membersData = (member) => {
    if (member?.team === null) {
      return (
        <td
          onClick={(e) => goToUrl(`/id/${member?.user?.url}`)}
          className={classNames("bracketFFANameHover", styles.name)}
        >
          <span className={styles.logo}>
            <img src={member?.user?.avatar ? member?.user?.avatar : noImage} />
          </span>
          {member?.user?.nickname}
        </td>
      );
    } else if (member?.user === null) {
      return (
        <td
          onClick={(e) => goToUrl(`/teams/team/${member?.team?.id}`)}
          className={classNames("bracketFFANameHover", styles.name)}
        >
          <span className={styles.logo}>
            <img src={member?.team?.logo ? member?.team?.logo : noImage} />
          </span>
          {member?.team?.name}
        </td>
      );
    } else {
      return (
        <td className={classNames("bracketFFANameHover", styles.name)}>
          <span className={styles.logo}>
            <img src={noImage} />
          </span>
          name
        </td>
      );
    }
  };
  // функции для получения даты
  const getEndMatchDate = () => {
    const testDateUtc = moment.utc(
      match?.stageFfa?.endedAt
        ? match?.stageFfa?.endedAt
        : match?.stageFfa?.startedAt
    );
    return moment(testDateUtc).local().format("D MMMM YYYY, h:mm a (UTC Z)");
  };

  const getStartMatchDate = () => {
    const testDateUtc = moment.utc(match?.stageFfa?.startedAt);
    return moment(testDateUtc).local().format("D MMMM YYYY, h:mm a (UTC Z)");
  };

  const goToUrl = (url) => {
    history.push(url);
  };
  // MODAL
  const handleChangeBestOf = (event) => {
    setRoundBestOf(event.target.value);
  };
  const setTimeRound = (time, bestOf) => {};
  console.log(
    inputDate,
    "inputDate",
    inputTime,
    "inputTime",
    roundBestOf,
    "roundBestOf"
  );
  const applyRoundSettings = () => {
    API.request({
      ...API_ROUTER.tournaments.setRoundSettings,
      pathKeys: {
        roundId: roundId,
      },
      data: {
        gameToWin: roundBestOf,
        activeAt:
          moment(inputDate).format("YYYY-MM-DD") + " " + inputTime + ":00",
      },
    })
      .then((res) => {
        toast.success("Round settings changed successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  };
  useEffect(() => {
    const time = match?.round?.startedAt;
    const bestOf = match?.round?.mode;
    const roundId = match?.round?.id;
    if (time) {
      const correctTime = time?.split(" ")[1].split(":");
      setInputTime(`${correctTime[0]}:${correctTime[1]}`);
      setInputDate(new Date(time?.split(" ")[0]));
    }
    if (bestOf) {
      switch (bestOf) {
        case "Bo1":
          setRoundBestOf(1);
          break;
        case "Bo3":
          setRoundBestOf(2);
          break;
        case "Bo5":
          setRoundBestOf(3);
          break;
        case "Bo7":
          setRoundBestOf(4);
          break;
      }
    }
    if (roundId) {
      setRoudnId(roundId);
    }
  }, [match?.round]);
  useEffect(() => {
    setMembersState(match?.stageFfa?.matchFfaMembers);
  }, [match?.stageFfa?.matchFfaMembers]);
  return (
    <>
      <div
        {...events}
        ref={dragRef}
        className={classNames("matchMembersWrapperBg", styles.detailWrap)}
      >
        <TransModal disableEscapeKeyDown open={openRoundChange}>
          <DialogContent className={classNames("modalBg", styles.modal)}>
            <div className={styles.modalContainer}>
              <InputLabel className={styles.roundChangeTitle}>Time</InputLabel>
              <input
                className={styles.roundChangeInputTime}
                type="time"
                value={inputTime}
                onChange={(e) => setInputTime(e.target.value)}
              />
              <InputLabel className={styles.roundChangeTitle}>Date</InputLabel>
              <DateInput selected={inputDate} onChange={setInputDate} />
              <InputLabel
                style={{ marginTop: "25px" }}
                className={styles.roundChangeTitle}
              >
                Match's format
              </InputLabel>
              <Select
                className={styles.roundChangeSelect}
                value={roundBestOf}
                onChange={handleChangeBestOf}
                label="Match's format"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
                inputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              >
                <MenuItem value={1}>BO1</MenuItem>
                <MenuItem value={2}>BO3</MenuItem>
                <MenuItem value={3}>BO5</MenuItem>
                <MenuItem value={4}>BO7</MenuItem>
              </Select>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => setOpenRoundChange(!openRoundChange)}>
                  close
                </Button>
                <Button onClick={() => applyRoundSettings()}>save</Button>
              </div>
            </div>
          </DialogContent>
        </TransModal>
        <div
          style={{
            padding: "10px",
            background: "rgb(255 255 255 / 10%)",
            textTransform: "capitalize",
            cursor: isAllowRoundUpdate && "pointer",
          }}
          onClick={(e) => {
            isAllowRoundUpdate && setOpenRoundChange(!openRoundChange);
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            {match?.status ? match?.status : ""} :{" "}
            {match?.status === "ended"
              ? getEndMatchDate()
              : getStartMatchDate()}{" "}
            - {match?.mode ? match?.mode : ""}
            <div style={{ color: "rgb(255 255 255 / 40%)" }}>
              {match?.name ? match?.name : ""}
            </div>
          </div>
        </div>
        <div className={styles.scrollWrap}>
          <div className={styles.tableWrap}>
            <table
              className={classNames("bracketTableFFAColors", styles.table)}
            >
              <caption>MATCH MEMBERS FFA</caption>
              <colgroup>
                <col className={styles.col_team} />
                <col className={styles.col_space} />
                <col className={styles.col_placement} />
                <col className={styles.col_kills} />
                <col className={styles.col_winLose} />
                <col className={styles.col_total} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" colSpan={2}>
                    <FormattedMessage id="match.ticker.team" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="match.ticker.placement" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="match.ticker.kills" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="match.ticker.winlose" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="match.ticker.total" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {membersState?.map((el, index) => (
                  <tr key={el?.id}>
                    <td>#{index + 1}</td>

                    {membersData(el?.tournamentMember)}

                    <>
                      <td>{el?.placement}</td>
                      <td>{el?.kills}</td>
                      {match?.status === "soon" ? (
                        <td>
                          <FormattedMessage id="match.ticker.soon" />
                        </td>
                      ) : (
                        <td>
                          {el?.isWinner === true ? (
                            <FormattedMessage id="match.ticker.win" />
                          ) : (
                            <FormattedMessage id="match.ticker.lose" />
                          )}
                        </td>
                      )}
                    </>

                    <td>{el?.totalScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchMembersFFA;
