import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { Modal } from "../../../../components/UI";

import styles from "./styles.module.scss";

export default function ProfileModalInformation({
  showQrModal,
  setOpenModal,
  citizenship,
  country,
  firstName,
  lastName,
  age,
  gender,
  passport,
  discord,
  sites,
}) {
  return (
    <>
      <Modal
        className={styles.modal__wrapper}
        isTransparent={true}
        open={showQrModal}
        onClose={() => setOpenModal(!true)}
      >
        <div className={classNames("modalBg", styles.modal)}>
          <div
            className={classNames("modalTopBg", styles.modal__header__about)}
          >
            <div className={styles.modal__header__about__title}>About</div>
            <i
              onClick={() => setOpenModal(!true)}
              className="icon icon-exit"
              style={{ width: "36px", height: "36px", marginRight: "13px" }}
            />
          </div>

          <div className={styles.modal__title}>
            <div className={styles.modal__info}>
              {!!citizenship && (
                <div className={styles.modal__info__value}>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="id.citizenship" />
                  </div>
                  <div className={styles.modal__info__value__second}>
                    {citizenship}
                  </div>
                </div>
              )}
              {!!firstName && (
                <div className={styles.modal__info__value}>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="global.forms.labels.firstName" />
                  </div>
                  <div className={styles.modal__info__value__second}>
                    {firstName}
                  </div>
                </div>
              )}
              {!!country && (
                <div className={styles.modal__info__value}>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="id.country" />
                  </div>
                  <div className={styles.modal__info__value__second}>
                    {country}
                  </div>
                </div>
              )}

              {!!lastName && (
                <div className={styles.modal__info__value}>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="global.forms.labels.lastName" />
                  </div>
                  <div className={styles.modal__info__value__second}>
                    {lastName}
                  </div>
                </div>
              )}
              {!!age && (
                <div className={styles.modal__info__value}>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="id.age" />
                  </div>
                  <div className={styles.modal__info__value__second}>{age}</div>
                </div>
              )}
              {!!gender && (
                <div className={styles.modal__info__value}>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="global.forms.labels.gender" />
                  </div>
                  <div className={styles.modal__info__value__second}>
                    {gender}
                  </div>
                </div>
              )}
              {!!passport && (
                <div className={styles.modal__info__value}>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="id.gamePass" /> :
                  </div>
                  <div className={styles.modal__info__value__second}>
                    {passport}
                  </div>
                </div>
              )}
              {!!discord && (
                <div className={styles.modal__info__value}>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="teams.form.discord" />
                  </div>
                  <div className={styles.modal__info__value__second}>
                    {discord}
                  </div>
                </div>
              )}
              {!!sites?.length && (
                <div className={styles.modal__info__value}>
                  <a
                    href={
                      sites[0]?.url ? sites[0]?.url : "https://www.facebook.com"
                    }
                  ></a>
                  <div className={styles.modal__info__value__first}>
                    <FormattedMessage id="teams.form.facebook" />
                  </div>
                  <div className={styles.modal__info__value__second}>
                    <i
                      className="icon icon-facebook"
                      style={{
                        width: "25px",
                        height: "25px",
                        background: "orange",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
