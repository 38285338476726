import React, { useState } from "react";

import {NavigationTabs} from "./components";

import {MENU_TABS} from './constants';

const Calendar = () => {
  const [activeTab, setActiveTab] = useState();

  return (
    <div>
      <div>
          <NavigationTabs onChangeActiveTab={(id) => setActiveTab(id)} />

          {MENU_TABS?.map((tab) => (
              tab?.id === activeTab && (
                  <div key={tab?.id}>
                    {tab.components()}
                  </div>
              )
          ))}
      </div>
    </div>
  );
};

export default Calendar;
