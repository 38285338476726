import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";

import SendResultModal from "./modal";
import { CustomSelect } from "../../../../components/form";
import { Preloader, CheckBox } from "../../../../components/UI";

import { SCORE_OPTIONS, NUMBER_TEAM_TWO, NUMBER_TEAM_ONE } from "./constants";

import styles from "./styles.module.scss";

export const ResultMatchForm = ({
  countGames,
  matchId,
  matchWinner,
  onClose,
}) => {
  const [defaultScore, setDefaultScore] = useState(null);
  const [teamDefaultNumber, setTeamDefaultNumber] = useState(null);
  const [defaultScoreTeamTwo, setDefaultScoreTeamTwo] = useState(null);
  const [selectedCountRound, setSelectedCountRound] = useState(null);
  const [noShowCheckedTeamOne, setNoShowCheckedTeamOne] = useState(false);
  const [noShowCheckedTeamTwo, setNoShowCheckedTeamTwo] = useState(false);

  const [openSendResultModal, setOpenSendResultModal] = useState(false);
  const [optionsTeamOne, setOptionsTeamOne] = useState([]);
  const [optionsTeamTwo, setOptionsTeamTwo] = useState([]);
  const [error, setError] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const getOptionsValue = () => {
    const newSelectedCountRound = SCORE_OPTIONS.find(
      (el) => el.id === countGames
    );
    setSelectedCountRound(newSelectedCountRound);
    let newOptionsTeamOne = [],
      newOptionsTeamTwo = [];
    Object.keys(newSelectedCountRound.options)?.map((el, i) => {
      newOptionsTeamOne = [...newOptionsTeamOne, { id: i, value: el }];
      newOptionsTeamTwo = [...newOptionsTeamTwo, { id: i, value: el }];
    });

    setOptionsTeamOne(newOptionsTeamOne);
    setOptionsTeamTwo(newOptionsTeamTwo);
  };

  const changeScoreTeamOne = (val) => {
    if (selectedCountRound?.options) {
      if (!teamDefaultNumber) {
        setTeamDefaultNumber(NUMBER_TEAM_ONE);
      }

      if (teamDefaultNumber === NUMBER_TEAM_ONE || !teamDefaultNumber) {
        let newOptions = [];
        selectedCountRound?.options[val]?.map((el, index) => {
          newOptions = [...newOptions, { id: index, value: el }];
        });
        setOptionsTeamTwo(newOptions);
        setDefaultScoreTeamTwo(null);
      }

      setDefaultScore(val);
    }
  };

  const changeScoreTeamTwo = (val) => {
    if (selectedCountRound?.options) {
      if (!teamDefaultNumber) {
        setTeamDefaultNumber(NUMBER_TEAM_TWO);
      }

      if (teamDefaultNumber === NUMBER_TEAM_TWO || !teamDefaultNumber) {
        let newOptions = [];
        selectedCountRound?.options[val]?.map((el, index) => {
          newOptions = [...newOptions, { id: index, value: el }];
        });
        setOptionsTeamOne(newOptions);
        setDefaultScore(null);
      }

      setDefaultScoreTeamTwo(val);
    }
  };
  const onCheckboxChange = (e) => {
    if (e.target.name === "noShowTeamOne")
      setNoShowCheckedTeamOne(!noShowCheckedTeamOne);

    if (e.target.name === "noShowTeamTwo")
      setNoShowCheckedTeamTwo(!noShowCheckedTeamTwo);
  };
  const onHandleSubmit = () => {
    setError([]);
    setIsLoad(true);
    API.request({
      ...API_ROUTER.tournaments.setResultMatch,
      pathKeys: {
        matchId: matchId,
      },
      data: {
        score1: !noShowCheckedTeamOne
          ? Number(optionsTeamOne[defaultScore]?.value)
          : "noShow",
        score2: !noShowCheckedTeamTwo
          ? Number(optionsTeamTwo[defaultScoreTeamTwo]?.value)
          : "noShow",
      },
    })
      .then((res) => {
        toast.success("Results saved");
        onClose();
      })
      .catch((err) => {
        if (err?.data?.errors) {
          const errorText = Object.values(err?.data?.errors);
          setError(errorText);
        }
      })
      .finally(() => setIsLoad(false));
  };

  useEffect(() => {
    getOptionsValue();
  }, []);
  return (
    <div className={classNames("resultMatchWrapperBg", styles.wrapper)}>
      {isLoad ? (
        <Preloader />
      ) : (
        <>
          <div className={styles.title}>
            <FormattedMessage id="match.ticker.enter.result" />
          </div>
          <div className={styles.score}>
            <div className={styles.selectWrapper}>
              {!!optionsTeamOne.length &&
                (!noShowCheckedTeamOne ? (
                  <CustomSelect
                    label="match.ticker.selectScore1"
                    onChange={(val) => changeScoreTeamOne(val)}
                    options={optionsTeamOne}
                    defaultValue={optionsTeamOne[defaultScore]}
                  />
                ) : (
                  <CustomSelect label="match.ticker.noShow" />
                ))}
            </div>
            <div className={styles.separator}>:</div>
            <div className={styles.selectWrapper}>
              {!!optionsTeamTwo.length &&
                (!noShowCheckedTeamTwo ? (
                  <CustomSelect
                    onChange={(val) => changeScoreTeamTwo(val)}
                    options={optionsTeamTwo}
                    label="match.ticker.selectScore2"
                    defaultValue={optionsTeamTwo[defaultScoreTeamTwo]}
                  />
                ) : (
                  <CustomSelect label="match.ticker.noShow" />
                ))}
            </div>
          </div>
          <SendResultModal
            openSendResultModal={openSendResultModal}
            setOpenSendResultModal={setOpenSendResultModal}
            onHandleSubmit={onHandleSubmit}
          />
          <div className={styles.wrapperCheckBox}>
            <div className={styles.checkBox}>
              <CheckBox
                name="noShowTeamOne"
                onChange={onCheckboxChange}
                checked={noShowCheckedTeamOne}
              ></CheckBox>
            </div>

            <div className={styles.checkBoxTextWrapper}>
              <FormattedMessage id="match.ticker.noshow" />
              <span
                className={classNames(
                  "matchInfoScoreboardBg",
                  styles.checkBoxText
                )}
              >
                <FormattedMessage id="match.ticker.tickbox" />
              </span>
            </div>

            <div className={styles.checkBox}>
              <CheckBox
                name="noShowTeamTwo"
                onChange={onCheckboxChange}
                checked={noShowCheckedTeamTwo}
              ></CheckBox>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={classNames("resultMatchButtonBg", styles.button)}
              onClick={() => setOpenSendResultModal(!openSendResultModal)}
            >
              <FormattedMessage id="match.ticker.confirm" />
            </button>
          </div>
          {!!error.length && (
            <>
              {error.map((el, index) => (
                <p key={index} className={styles.error}>
                  {el}
                </p>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default ResultMatchForm;
