import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

import "./Games.css";
import NoImage from "../../assets/no-image.png";
import { useDispatch, useSelector } from "react-redux";
import loadGames from "../../helpers/games/loadGames";
import addGame from "../../helpers/games/addGame";
import { toast } from "react-toastify";
import { API, API_ROUTER } from "../../api";
import { FormattedMessage, injectIntl } from "react-intl";

const Games = (props) => {
  const { intl } = props;

  const [selectedGame, changeGameDiscipline] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [games, setGames] = useState([]);
  const [nickInput, setNickInput] = useState("");
  const [gamesMoba, setGamesMoba] = useState([]);
  const [gamesFPS, setGamesFPS] = useState([]);
  const [gamesOther, setGamesOther] = useState([]);
  const [gamesSport, setGamesSport] = useState([]);
  const [gamesFight, setGamesFight] = useState([]);
  const [gamesBattle, setGamesBattle] = useState([]);

  const history = useHistory();

  const user = useSelector((state) => state.userData);

  // const changeGame = () => {
  //   addGame(selectedGame)
  //     .then((res) => {
  //       toast.success("Game successfully added");
  //       history.push(`/id/${user.url}`);
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //       toast.error(e.message);
  //     });
  // };
  function addGame() {
    const data = {
      gameId: selectedGame.id,
      nickname: nickInput,
    };
    API.request({
      ...API_ROUTER.games.addGameToUserGames,
      data,
    })
      .then(() => {
        toast.success("Game successfully added");
        history.push(`/id/${user.url}`);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    API.request({
      ...API_ROUTER.games.getGames,
    })
      .then((res) => {
        setGames(res.games);
        setIsLoad(true);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setGamesFPS(games?.filter((value) => value?.groupName === "FPS"));
    setGamesMoba(games?.filter((value) => value?.groupName === "MOBA"));
    setGamesOther(games?.filter((value) => value?.groupName === "OTHER GAMES"));
    setGamesSport(
      games?.filter((value) => value?.groupName === "SPORTS SIMULATORS")
    );
    setGamesFight(
      games?.filter((value) => value?.groupName === "FIGHTING GAMES")
    );
    setGamesBattle(
      games?.filter((value) => value?.groupName === "BATTLE ROYALE")
    );
  }, [games]);

  function showToast() {
    toast.success(
      "Add the game to your profile so you can be added to the roster"
    );
  }
  function addGameGroup() {
    const groupGames = [];
    games?.filter((value) => groupGames.push(value.groupName === "FPS"));
    return groupGames;
  }
  const renderGames = (gamesList) => {
    return gamesList.map((el) => (
      <li
        className={
          el?.id === selectedGame.id
            ? "games__item-list  games__item-list--active"
            : "games__item-list"
        }
        onClick={(e) => changeGameDiscipline(el)}
        key={el?.id}
      >
        <img
          className="games__item-list-image"
          src={el?.logo}
          alt={el?.title}
          width="100"
          height="100"
        />
        {el?.title.length > 16 ? (
          <p className="games__item-list-text">
            {el?.title.slice(0, 16) + "..."}
          </p>
        ) : (
          <p className="games__item-list-text">{el?.title}</p>
        )}
        {el?.id === selectedGame.id ? (
          <input
            type="text"
            value={nickInput}
            onChange={(e) => setNickInput(e.target.value)}
            className="games__input-name"
            placeholder={intl.formatMessage({
              id: "global.forms.labels.nickname",
              defaultMessage: "nickname",
            })}
          />
        ) : (
          ""
        )}
      </li>
    ));
  };
  return (
    <>
      {!isLoad && <LinearProgress />}
      {!!isLoad && (
        <div>
          <div className="games">
            <div className="games__title-icon">
              <h1 className="games__title">
                {" "}
                <FormattedMessage id="id.games" />
              </h1>
              <i
                className="icon icon-exclamation-mark"
                onClick={() => showToast()}
                style={{ background: "#f5d3a4" }}
              ></i>
            </div>
            <div className="games__list__container">
              <div className="games__list__group">FPS</div>
              <ul className="games__list">{renderGames(gamesFPS)}</ul>
            </div>

            <div className="games__list__container">
              <div className="games__list__group">MOBA</div>
              <ul className="games__list">{renderGames(gamesMoba)}</ul>
            </div>

            <div className="games__list__container">
              <div className="games__list__group">BATTLE ROYALE</div>
              <ul className="games__list">{renderGames(gamesBattle)}</ul>
            </div>

            <div className="games__list__container">
              <div className="games__list__group">SPORTS SIMULATORS</div>
              <ul className="games__list">{renderGames(gamesSport)}</ul>
            </div>

            <div className="games__list__container">
              <div className="games__list__group">FIGHTING SIMULATORS</div>
              <ul className="games__list">{renderGames(gamesFight)}</ul>
            </div>

            <div className="games__list__container">
              <div className="games__list__group">OTHER GAMES</div>
              <ul className="games__list">{renderGames(gamesOther)}</ul>
            </div>

            <div className="submit-button-wrapper">
              <button
                className={`submit-button ${
                  !selectedGame ? "submit-button--disabled" : ""
                }`}
                onClick={() => addGame()}
              >
                <FormattedMessage id="global.forms.placeholders.select" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(Games);
