import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { API, API_ROUTER } from "../../api";

import { ContentBox, Container } from "../../components/UI";
import { getUrlParams } from "../../helpers";
import Logo from "../../components/logo";
import styles from "./styles.module.scss";
// import hellRaisers from "../../assets/HellRaisers.png";
// import asiaLogo from "../../assets/asia-logo.jpeg";

export default function PageNotFound() {
  const [teamDetails, setTeamDetails] = useState();
  const [ip, setIP] = useState("");
  const [errors, setErrors] = useState(false);
  const { token } = useParams();

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    API.request({
      ...API_ROUTER.teams.getTeamDetailsFromToken,
      pathKeys: {
        token: token,
      },
    })
      .then((res) => {
        console.log(res);
        setTeamDetails(res);
      })
      .catch((err) => (err ? setErrors(true) : setError(false)));

    getData();
  }, []);
  useEffect(() => {
    !!ip &&
      API.request({
        ...API_ROUTER.teams.acceptTeamInviteFromToken,
        pathKeys: {
          token: token,
        },
        data: {
          ip: ip,
        },
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => (err ? setErrors(true) : setError(false)));
  }, [ip]);
  console.log(errors);
  return (
    <Container>
      <ContentBox>
        <div className={styles.header}>
          {!errors ? (
            <p>you have successfully joined the team !</p>
          ) : (
            <p>
              Something went wrong. Please contact{" "}
              <a href="https://about.passport.gg/supportpage">US</a>
            </p>
          )}
        </div>
      </ContentBox>
      {!errors && (
        <div className={styles.content}>
          <div className={styles.contentText}>
            <div style={{ margin: "30px" }}>
              <Logo />
            </div>
            <p>Welcome to</p>
            <p>{teamDetails?.teamName}</p>
            <a href={`/teams/team/${teamDetails?.teamId}`}>GO TO THE TEAM</a>
          </div>
        </div>
      )}
    </Container>
  );
}
