import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import Wrapper from "../../../../../../components/wrapper";
import { Modal, Button } from "../../../../../../components/UI";
import { isAuthenticated } from "../../../../../../helpers";

import styles from "./styles.module.scss";

const SendResultModal = ({
  openSendResultModal,
  setOpenSendResultModal,
  onHandleSubmit,
}) => {
  let history = useHistory();

  return (
    <Modal
      className={styles.modal__wrapper}
      isTransparent={true}
      open={openSendResultModal}
      onClose={() => setOpenSendResultModal(!openSendResultModal)}
    >
      <div className={classNames("modalBg", styles.modal)}>
        <img
          className={classNames("modalLogo", styles.modal__image)}
          alt="modal__image"
        />

        <div className={styles.modal__title}>
          <div className={styles.modal__header__about__title}>
            <FormattedMessage id="match.ticker.send.result.warning" />
          </div>
          <div className={styles.modal__info}>
            <FormattedMessage id="match.ticker.send.result.message" />
          </div>
          <div className={styles.modal__title__buttons}>
            <div className={styles.go__to__buttons}>
              <div className={styles.send_result}>
                <Button
                  label="send result"
                  type="button"
                  color="white"
                  active
                  size="sm"
                  variant="changePassword"
                  action={() => {
                    onHandleSubmit();
                    setOpenSendResultModal(!openSendResultModal);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.close__button}>
            <p onClick={() => setOpenSendResultModal(!openSendResultModal)}>
              <FormattedMessage id="match.ticker.send.result.cancel" />
            </p>
          </div>
          <div className={styles.support__button}>
            <a href="https://about.passport.gg/supportpage" target={"_blank"}>
              <FormattedMessage id="footer.support" />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SendResultModal;
