import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Close } from "@material-ui/icons";

import { API, API_ROUTER } from "../../../../api";

import { Preloader } from "../../../../components/UI";
import TournamentItem from "../../../../components/list-items/components/tournamentItem";
import { CustomSelect } from "../../../../components/form";

import { isAuthenticated } from "../../../../helpers";
import { MONTHS_LIST } from "../../constants";

import styles from "./styles.module.scss";

export const TournamentsList = () => {
  const [isAuthUser, setIsAuthUser] = useState(isAuthenticated());
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tournamentList, setTournamentList] = useState();
  const [pageTournament, setPageTournament] = useState(1);
  const [defaultGame, setDefaultGame] = useState(null);
  const [defaultMonth, setDefaultMonth] = useState(null);

  const getAuthTournament = () => {
    setIsLoading(true);
    const urlParams = {};

    if (defaultMonth?.id) {
      urlParams["month"] = defaultMonth?.id;
    }

    if (defaultGame?.id) {
      urlParams["gameId"] = defaultGame?.id;
    }

    API.request({
      ...API_ROUTER.tournaments.getAuthTournaments,
      urlParams,
      pathKeys: {
        page: pageTournament,
        limit: 10,
      },
    })
      .then((res) => {
        setTournamentList(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const getTournament = () => {
    setIsLoading(true);
    let urlParams = {};

    if (defaultMonth?.id) {
      urlParams["month"] = defaultMonth?.id;
    }

    if (defaultGame?.id) {
      urlParams["gameId"] = defaultGame?.id;
    }

    API.request({
      ...API_ROUTER.tournaments.getTournaments,
      urlParams,
      pathKeys: {
        page: pageTournament,
        limit: 10,
      },
    })
      .then((res) => {
        setTournamentList(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const getGames = () => {
    setIsLoading(true);
    API.request({
      ...API_ROUTER.games.getGames,
    })
      .then((res) => {
        let newOptions = [];
        res?.games?.map((el, index) => {
          newOptions = [...newOptions, { id: el.id, value: el.title }];
        });
        setGames(newOptions);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleChangeTournaments = (event, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setPageTournament(value);
  };

  const changeSelectGame = (val) => {
    const findEl = games?.find((el) => el.id === val);
    if (findEl) {
      setDefaultGame(findEl);
    }
  };

  const changeSelectMonth = (val) => {
    const findEl = MONTHS_LIST?.find((el) => el.id === val);
    if (findEl) {
      setDefaultMonth(findEl);
    }
  };
  const clearFilters = () => {
    setDefaultMonth(null);
    setDefaultGame(null);
  };

  useEffect(() => {
    if (isAuthUser) {
      return getAuthTournament();
    }

    getTournament();
  }, [pageTournament, defaultGame, defaultMonth]);

  useEffect(() => {
    getGames();
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <section className={styles.calendarList}>
      <div className={styles.filters}>
        <CustomSelect
          onChange={(val) => changeSelectGame(val)}
          options={games}
          label="createEvent.title.titleGames.placeholder"
          defaultValue={defaultGame}
        />

        <div className={styles.wrapperSelect}>
          <CustomSelect
            onChange={(val) => changeSelectMonth(val)}
            options={MONTHS_LIST}
            label="calendar.filter.selectMonth"
            defaultValue={defaultMonth}
          />
        </div>
        <button className={styles.clearButton} onClick={clearFilters}>
          {/*<span className={styles.label}>*/}
          {/*    <FormattedMessage id="ratings.clearFilters" />*/}
          {/*</span>*/}
          <Close />
        </button>
      </div>

      {!tournamentList?.tournaments?.length && (
        <div className="empty-data">
          <FormattedMessage id="calendar.noTournaments" />
        </div>
      )}

      <article>
        <ul>
          {tournamentList?.tournaments?.map((items) => (
            <TournamentItem {...items} key={items.id} />
          ))}
        </ul>
      </article>

      <div className={styles.paginatorWrapper}>
        <Pagination
          classes={{ ul: styles.pagination }}
          count={tournamentList?.paginator?.lastPage}
          page={tournamentList?.paginator?.currentPage}
          onChange={handleChangeTournaments}
          variant="outlined"
        />
      </div>
    </section>
  );
};

export default TournamentsList;
