import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { API, API_ROUTER } from "../../../../api";

import { isAuthenticated } from "../../../../helpers";

import { fulldate } from "./constant/date";

import banerAsia from "../../../../assets/images/event-asia-baner.png";
import asiaLogo from "../../../../assets/asia-logo.jpeg";

import styles from "./styles.module.scss";

function CommunityHeader({ community }, isFollow) {
  const [star, tongleStar] = useState(isFollow);

  let { communityId } = useParams();

  const history = useHistory();

  function goToUrl(url) {
    history.push(url);
    if (!isAuthenticated()) {
      localStorage.removeItem("eventId");
      localStorage.removeItem("tournamentId");
      localStorage.removeItem("profileUrl");
      return localStorage.setItem("communityId", communityId);
    }
  }
  const tongStar = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowCommunity,
      pathKeys: {
        communityId: communityId,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowCommunity,
              pathKeys: {
                communityId: communityId,
              },
            })
              .then((res) => {
                toast.success(`You unfollowed this community`);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followCommunity,
              data: {
                communityId: communityId,
              },
            })
              .then((res) => {
                toast.success(`You followed this community`);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));
    if (star) {
      tongleStar(false);
    } else tongleStar(true);
  };

  return (
    <>
      <section
        className={styles.events__banner}
        style={{
          background: `url(${
            community?.backgroundLogo ? community?.backgroundLogo : banerAsia
          }) no-repeat center`,
        }}
      >
        <img
          className={styles.events__banner__image}
          src={community?.logo ? community?.logo : asiaLogo}
          alt="logo"
          width="90"
          height="90"
        />
      </section>

      <section className={classNames("eventHeaderBg", styles.events__title)}>
        <div className={styles.community__header}>
          <h3 className={styles.events__title__name}>
            {community?.name ? community?.name : " EVENT NAME"}
          </h3>
        </div>

        <div className={styles.community__header__info}>
          <h4 className={styles.community__description__title}>
            {" "}
            <FormattedMessage id="tournaments.details.info.tabs.information" />
          </h4>
          <p className={styles.community__description}>
            {community?.description ? community?.description : false}
          </p>
          <article className={styles.events__heart__abs}>
            {isAuthenticated() ? (
              star === false ? (
                <div
                  className={classNames(
                    "eventHeaderButtonBg",
                    styles.events__button__followed
                  )}
                  onClick={() => tongStar()}
                >
                  <h1 className={styles.events__button__text}>
                    <FormattedMessage id="id.follow" />
                  </h1>
                </div>
              ) : (
                <div
                  className={classNames(
                    "eventHeaderButtonBg",
                    styles.events__button
                  )}
                  onClick={() => tongStar()}
                >
                  <h1 className={styles.events__button__text}>unfolow</h1>
                </div>
              )
            ) : (
              <div
                className={classNames(
                  "eventHeaderButtonBg",
                  styles.events__button
                )}
              >
                <h1 className={styles.events__button__text}>
                  <div onClick={() => goToUrl("/login")}>
                    <FormattedMessage id="global.buttons.login" />
                  </div>
                </h1>
              </div>
            )}
          </article>
        </div>
      </section>
    </>
  );
}
export default CommunityHeader;
