import React, { useState, useEffect, Component } from "react";
import styled, { css } from "styled-components";
import PlacesAutocomplete from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";

import store from "../../redux/store";
import {
  getUserData,
  setInterfaceLang,
  showQrModal,
} from "../../redux/actions";
import { API, API_ROUTER } from "../../api";
import { toast } from "react-toastify";

//
import TitleRow from "../../components/title-row";
import InfoInput from "../../components/info-input";
//
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import {
  Input,
  PhoneNumber,
  Select,
  Button,
  SectionTitle,
  ContentBox,
} from "../../components/UI";
import {
  isFieldEmpty,
  numbersOnly,
  getValueFromSelect,
  setValueToSelect,
  getLabelFromSelect,
  getArrayFromSelect,
  setArrayToSelect,
  dataUriToBlob,
  isSelectEmpty,
  isFieldEmptyAndLength,
  isUserUrlChangeValid,
} from "../../helpers";
import {
  LANGUAGE_SPEAK_OPTIONS,
  INTERFACE_LANGUAGES_OPTIONS,
} from "../../config";
import { translations } from "../../i18n";
import UploadAvatar from "../../components/upload-avatar";
import userData from "../../redux/reducers/particles/userData";

import {
  handleLoadAvatar,
  isDateValid,
  reverseDate,
  getDate,
} from "../team-create-change/const";
import Styled from "./styles";

//
const initialErrors = {
  firstName: "",
  nickname: "",
  birthDate: "",
  username: "",
  citizenship: "",
  country: "",
  url: "",
  discord: "",
};

const PersonalInfo = (props) => {
  const { intl, history, interfaceLang } = props;
  // get user Info
  const userData = useSelector((state) => state.userData);
  const languagesList = useSelector((state) => state.languagesList);
  const countriesList = useSelector((state) => state.countriesList);
  const [formFields, setFormFields] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  const [errors, setErrors] = useState(initialErrors);
  const [loadedImg, setLoadedImg] = useState(null);

  useEffect(() => {
    if (userData) {
      return setUserSettingStae();
    } else {
      return setFormFields({ ...formFields, error: true });
    }
  }, [userData]);

  function setUserSettingStae() {
    setFormFields({
      ...userData,
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      nickname: userData.nickname || "",
      regions: userData.region || "",
      languages: !!userData?.languages
        ? [getLanguages(userData?.languages)].flat()
        : [],
      birthDate: (userData.birthDate && userData.birthDate) || "",
      gender: setValueToSelect(userData.gender) || "",

      citizenship: setValueToSelect(userData.citizenship),
      country: setValueToSelect(userData.country),
      city: userData.city || "",
      about: userData.about ? userData.about : "",
      discord: userData.discord ? userData.discord : "",

      facebookLink: userData?.sites[0]?.url
        ? userData.sites[0].url
        : "https://www.facebook.com",

      url: userData.url ? userData.url : "",
    });
    function getLanguages() {
      return userData?.languages?.map((el) =>
        languagesList?.find((item) => item?.value === el)
      );
    }
  }
  const genderOptions = [
    {
      label: intl.formatMessage({ id: "gender.male" }),
      value: "Male",
    },
    {
      label: intl.formatMessage({ id: "gender.female" }),
      value: "Female",
    },
    {
      label: intl.formatMessage({ id: "gender.other" }),
      value: "Other",
    },
  ];

  function getCountryFromValue(languages) {
    languages?.map((el) => languagesList.find((item) => item?.value === el));
  }

  function onImageSelect(event) {
    const file = event.target.files[0];
    if (file) {
      handleLoadAvatar(file, setLoadedImg);
    }
  }

  function inputHandler(e) {
    const { name, value } = e.target;

    setFormFields({
      ...formFields,
      [name]: value,
    });
  }

  function selectHandler(value, selectName) {
    setFormFields({ ...formFields, [selectName]: value });
  }
  console.log(formFields);
  function onSubmit(e) {
    e.preventDefault();

    // validate
    const newErrors = initialErrors;

    newErrors.firstName = isFieldEmpty(formFields.firstName);
    newErrors.lastName = isFieldEmpty(formFields.lastName);
    newErrors.nickname = isFieldEmpty(formFields.nickname);
    newErrors.nickname = isFieldEmptyAndLength(formFields.nickname);
    newErrors.citizenship = isSelectEmpty(formFields.citizenship);
    newErrors.discord = isFieldEmpty(formFields.discord);
    newErrors.country = isSelectEmpty(formFields.country);

    newErrors.gender = isSelectEmpty(formFields.gender);
    // errors.facebookLink = isFieldEmpty(formFields.facebookLink);

    // errors.username = isFieldEmpty(formFields.username);
    newErrors.url = isUserUrlChangeValid(formFields.url);
    newErrors.birthDate = isDateValid(formFields.birthDate, true);
    setErrors({ ...errors, ...newErrors });

    if (!Object.values(newErrors).some((value) => value.length)) {
      let bod = null;
      if (formFields.birthDate) {
        bod = new Date(formFields.birthDate);
        bod = bod.toISOString().split("T")[0];
      }

      const params = {
        ...API_ROUTER.user.setUserData,
        data: {
          firstName: formFields.firstName,
          lastName: formFields.lastName,
          nickname: formFields.nickname,
          discord: formFields.discord,

          birthDate: bod,
          gender: formFields.gender[0].value,
          language: formFields.language || "en",
          languages: getArrayFromSelect(formFields.languages || []),

          citizenship: formFields.citizenship[0].value,
          country: formFields.country[0].value,

          url: formFields.url,
          about: formFields.about,

          // facebookLink: values.facebookLink,

          phone: "+7 900 505 55 55",
          sites: [
            {
              site: "facebook",
              url: formFields.facebookLink,
            },
          ],
        },
      };

      API.request(params, true)
        .then((resp) => {
          console.log(resp);

          if (loadedImg) {
            const blob = dataUriToBlob(loadedImg);
            const formData = new FormData();
            formData.append("media", blob);
            formData.append("mediaType", "userAvatar");
            formData.append("mediaOwnerId", resp.id);

            return API.request(
              {
                ...API_ROUTER.media.create,
                headers: {
                  "Content-type": "multipart/form-data",
                },
                data: formData,
              },
              true
            );
          }
        })
        .then((res) => {
          toast.success("Profile updated");

          window.location.href = `/profile/settings`;
        })
        .catch((err) =>
          err.data.errors.nickname
            ? toast.error(err.data && "Nickname " + err.data.errors.nickname)
            : toast.error(err.data && err.data.message)
        );
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }
  const onLanguageChange = (value) => {
    const lang = "language";
    const [langObject] = value;
    localStorage.setItem("interfaceLang", langObject.value);
    // onSubmit(langObject.value);
    setFormFields({ ...formFields, [lang]: langObject.value });
  };
  console.log(formFields);

  const activateAccount = () => {
    API.request({
      ...API_ROUTER.user.activateAccount,
    })
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => console.log(err));
  };

  function renderForm() {
    const interfaceLanguage =
      Object.keys(translations).indexOf(
        interfaceLang ? interfaceLang.toLowerCase() : "en"
      ) === -1
        ? "en"
        : userData?.language?.toLowerCase();
    const selectedLang = [
      INTERFACE_LANGUAGES_OPTIONS.find(
        (item) =>
          item.value === (interfaceLanguage && interfaceLanguage.toLowerCase())
      ) || { label: "", value: "" },
    ];
    return (
      <StyledInfo>
        <header className={classNames("transparentBg")}>
          <section>
            <div>
              <UploadAvatar
                user={userData}
                image={userData?.avatar}
                conditionRendering={(node) => node}
                onImageSelect={onImageSelect}
              />
            </div>
          </section>
          <TitleRow
            className={classNames("settingsMainTitleBg")}
            title={<FormattedMessage id="settings.personalInformation" />}
            color="white"
            boxshadow
            padding="16px 38px 19px"
            margin="30px 0px 0px"
            fontSize={26}
          />
        </header>
        <main className={classNames("transparentBg")}>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="containerFirst">
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="global.forms.labels.nickname" />}
                  required
                  color="white"
                  boxshadow
                  padding="9px 60px 7px 38px"
                />
                <InfoInput
                  onChange={inputHandler}
                  error={errors.nickname}
                  value={formFields.nickname}
                  icon="pencil"
                  name="nickname"
                  placeholder={intl.formatMessage({
                    id: "global.forms.labels.nickname",
                    defaultMessage: "first name",
                  })}
                  required
                />
              </section>
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={
                    <FormattedMessage id="global.forms.labels.firstName" />
                  }
                  required
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                />
                <InfoInput
                  error={errors.firstName}
                  value={formFields.firstName}
                  onChange={inputHandler}
                  name="firstName"
                  icon="pencil"
                  placeholder={intl.formatMessage({
                    id: "global.forms.labels.firstName",
                    defaultMessage: "first name",
                  })}
                  borderBottom
                  required
                />
              </section>
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="global.forms.labels.lastName" />}
                  required
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                />

                <InfoInput
                  error={errors.lastName}
                  value={formFields.lastName}
                  onChange={inputHandler}
                  name="lastName"
                  icon="pencil"
                  placeholder={intl.formatMessage({
                    id: "global.forms.labels.lastName",
                    defaultMessage: "last name",
                  })}
                  required
                />
              </section>
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="id.birthdate" />}
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                  required
                />
                <InfoInput
                  error={errors.birthDate}
                  value={formFields.birthDate}
                  onChange={inputHandler}
                  type="date"
                  min="1940-01-01"
                  max="2015-01-01"
                  name="birthDate"
                  icon="pencil"
                  placeholder="DD-MM-YYYY"
                  required
                />
              </section>
              {/* TODO change */}
              {/* <section>
            <TitleRow className={classNames("settingsTitleBg")}
              title="I SPEAK"
              color="white"
              padding="9px 60px 7px 38px"
              boxshadow
              // flags={getFlags()}
            />
            <Select
              clearable
              multiple
              values={formFields.languages}
              options={LANGUAGE_SPEAK_OPTIONS}
              onChange={(value) => selectHandler(value, "languages")}
            />
          </section> */}
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="global.forms.labels.gender" />}
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                />
                <Select
                  clearable
                  values={formFields.gender ? formFields.gender : []}
                  options={genderOptions || []}
                  onChange={(value) => selectHandler(value, "gender")}
                  type="text"
                  icon="pencil"
                  placeholder="Other"
                  error={errors.gender}
                />
              </section>
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={
                    <FormattedMessage id="global.forms.labels.citizenship" />
                  }
                  color="white"
                  padding="9px 60px 7px 38px"
                  boxshadow
                />
                <Select
                  clearable
                  error={errors.citizenship}
                  values={formFields.citizenship ? formFields.citizenship : []}
                  options={countriesList || []}
                  onChange={(value) => selectHandler(value, "citizenship")}
                />
              </section>
            </div>
            <div className="containerSecond">
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="settings.selectCountry" />}
                  color="white"
                  padding="9px 60px 7px 38px"
                  boxshadow
                />
                <Select
                  clearable
                  options={countriesList || []}
                  values={formFields.country ? formFields.country : []}
                  onChange={(value) => selectHandler(value, "country")}
                  error={errors.country}
                />
              </section>
              {/* <section>
              <TitleRow
                className={classNames("settingsTitleBg")}
                title="City"
                color="white"
                padding="9px 60px 7px 38px"
                boxshadow
              />
              <InfoInput
                // error={errors.facebookLink}
                value={formFields.name}
                onChange={inputHandler}
                name="city"
                type="text"
                icon="pencil"
                placeholder="City"
              />
              <PlacesAutocomplete
              value={formFields.city}
              onChange={handleChange}
              onSelect={handleSelect}
              searchOptions={options}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    style={{ color: "black" }}
                    {...getInputProps({
                      placeholder: "Enter Address...",
                    })}
                  />
                  <div>
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#a83232",
                            cursor: "pointer",
                            color: "black",
                          }
                        : {
                            backgroundColor: "#ffa901",
                            cursor: "pointer",
                            color: "black",
                          };

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                        >
                          {suggestion.description}
                          {suggestion.placeId}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            </section> */}
              {/* <section>
              <TitleRow
                className={classNames("settingsTitleBg")}
                title="I speek"
                color="white"
                boxshadow
                padding="9px 60px 7px 38px"
              />

              <Select
                clearable
                values={formFields?.languages ? formFields?.languages : []}
                options={languagesList ? languagesList : []}
                onChange={(value) => selectHandler(value, "languages")}
                padding
                multiple
              />
            </section> */}
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="teams.form.facebook" />}
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                />
                <InfoInput
                  // error={errors.facebookLink}
                  value={formFields.facebookLink}
                  onChange={inputHandler}
                  name="facebookLink"
                  type="text"
                  icon="pencil"
                  placeholder="http://facebook.com"
                />
              </section>
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="teams.form.discord" />}
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                />

                <InfoInput
                  error={errors.discord}
                  value={formFields.discord}
                  onChange={inputHandler}
                  name="discord"
                  type="text"
                  icon="pencil"
                  placeholder="Nickname#0123"
                  borderBottom
                  required
                />
              </section>

              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="settings.passportAddress" />}
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                />

                <InfoInput
                  error={errors.url}
                  value={formFields.url}
                  onChange={inputHandler}
                  name="url"
                  type="text"
                  icon="pencil"
                  placeholder={userData?.url}
                  borderBottom
                />
              </section>

              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={
                    <FormattedMessage id="global.forms.placeholders.email" />
                  }
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                />

                <InfoInput
                  name="url"
                  type="text"
                  icon="email"
                  placeholder={userData?.email}
                  disabled={true}
                  borderBottom
                />
              </section>
              <section>
                <TitleRow
                  className={classNames("settingsTitleBg")}
                  title={<FormattedMessage id="global.forms.labels.language" />}
                  color="white"
                  padding="9px 38px 7px"
                  boxshadow
                />

                <Select
                  values={selectedLang}
                  options={INTERFACE_LANGUAGES_OPTIONS}
                  onChange={onLanguageChange}
                />
              </section>
              {!userData.emailActivated && (
                <section>
                  <TitleRow
                    className={classNames("settingsTitleBg")}
                    title={<FormattedMessage id="settings.acitvate.account" />}
                    color="white"
                    padding="9px 38px 7px"
                    boxshadow
                  />
                  <div style={{ padding: "15px 28px" }}>
                    <Button
                      label={
                        <FormattedMessage id="global.forms.labels.send.email" />
                      }
                      type="button"
                      color="white"
                      active
                      size="sm"
                      variant="changePassword"
                      action={() => activateAccount()}
                    />
                  </div>
                </section>
              )}
            </div>
          </form>
          <section>
            <TitleRow
              className={classNames("settingsMainTitleBg")}
              title={<FormattedMessage id="settings.about" />}
              color="white"
              boxshadow
              padding="16px 38px 19px"
              margin="20px"
              fontSize={26}
            />

            <InfoInput
              value={formFields.about}
              onChange={inputHandler}
              name="about"
              type="text"
              icon="pencil"
              placeholder="Like to play"
              margin="0px 50px"
              borderBottom
            />
          </section>
          <section>
            <TitleRow
              className={classNames("settingsMainTitleBg")}
              title={<FormattedMessage id="password.reset.change" />}
              color="white"
              padding="16px 38px 19px"
              margin="20px"
              fontSize={26}
              boxshadow
            />
            <div style={{ padding: "15px 50px" }}>
              <Button
                label={<FormattedMessage id="password.reset.change" />}
                type="button"
                color="white"
                active
                size="sm"
                variant="changePassword"
                action={() => history.push("/password/change")}
              />
            </div>
          </section>
        </main>
        <footer className={classNames("transparentBg")}>
          <Button
            label={<FormattedMessage id="profile.cancelButton" />}
            type="button"
            color="white"
            active
            size="md"
            variant="settingsCancel"
            action={() => history.push(`/id/${userData?.url}`)}
          />
          <Button
            label={<FormattedMessage id="profile.editButton" />}
            active
            variant="settingsSave"
            size="md"
            action={(e) => onSubmit(e)}
          />
        </footer>
      </StyledInfo>
    );
  }
  // function formNew() {
  //   const interfaceLanguage =
  //     Object.keys(translations).indexOf(
  //       interfaceLang ? interfaceLang.toLowerCase() : "en"
  //     ) === -1
  //       ? "en"
  //       : userData?.language?.toLowerCase();
  //   const selectedLang = [
  //     INTERFACE_LANGUAGES_OPTIONS.find(
  //       (item) =>
  //         item.value === (interfaceLanguage && interfaceLanguage.toLowerCase())
  //     ) || { label: "", value: "" },
  //   ];
  //   return (
  //     <ContentBox>
  //       <Styled.Row>
  //         <Styled.Col className="full-width">
  //           <SectionTitle>
  //             <FormattedMessage id="settings.personalInfo" />
  //           </SectionTitle>
  //         </Styled.Col>
  //         <Styled.Col>
  //           <Styled.InnerBox>
  //             <Styled.FormGroup className="inline">
  //               <label>
  //                 <FormattedMessage id="global.forms.labels.firstName" /> *
  //               </label>
  //               <InfoInput
  //                 error={errors.firstName}
  //                 value={formFields.firstName}
  //                 onChange={inputHandler}
  //                 name="firstName"
  //                 icon="pencil"
  //                 placeholder={intl.formatMessage({
  //                   id: "global.forms.labels.firstName",
  //                   defaultMessage: "first name",
  //                 })}
  //                 borderBottom
  //                 required
  //               />
  //             </Styled.FormGroup>
  //             <Styled.FormGroup className="inline">
  //               <label>
  //                 <FormattedMessage id="global.forms.labels.lastName" />
  //               </label>
  //               <InfoInput
  //                 error={errors.lastName}
  //                 value={formFields.lastName}
  //                 onChange={inputHandler}
  //                 name="lastName"
  //                 icon="pencil"
  //                 placeholder={intl.formatMessage({
  //                   id: "global.forms.labels.lastName",
  //                   defaultMessage: "last name",
  //                 })}
  //                 required
  //               />
  //             </Styled.FormGroup>

  //             <Styled.FormGroup className="inline">
  //               <label>
  //                 <FormattedMessage id="global.forms.labels.gender" />
  //               </label>
  //               <Select
  //                 clearable
  //                 values={formFields.gender ? formFields.gender : []}
  //                 options={genderOptions || []}
  //                 onChange={(value) => selectHandler(value, "gender")}
  //                 type="text"
  //                 icon="pencil"
  //                 placeholder="Other"
  //                 error={errors.gender}
  //               />
  //             </Styled.FormGroup>
  //             <Styled.FormGroup className="inline">
  //               <label>
  //                 <FormattedMessage id="global.forms.labels.citizenship" />
  //               </label>
  //               <Select
  //                 clearable
  //                 error={errors.citizenship}
  //                 values={formFields.citizenship ? formFields.citizenship : []}
  //                 options={countriesList || []}
  //                 onChange={(value) => selectHandler(value, "citizenship")}
  //               />
  //             </Styled.FormGroup>

  //             <Styled.FormGroup className="inline">
  //               <label>
  //                 <FormattedMessage id="settings.passportAddress" />
  //               </label>
  //               <InfoInput
  //                 error={errors.url}
  //                 value={formFields.url}
  //                 onChange={inputHandler}
  //                 name="url"
  //                 type="text"
  //                 icon="pencil"
  //                 placeholder={userData?.url}
  //                 borderBottom
  //               />
  //             </Styled.FormGroup>
  //           </Styled.InnerBox>
  //         </Styled.Col>
  //         <Styled.Col>
  //           <Styled.InnerBox>
  //             <Styled.FormGroup className="inline">
  //               <label>
  //                 <FormattedMessage id="global.forms.labels.email" /> *
  //               </label>
  //               <InfoInput
  //                 name="url"
  //                 type="text"
  //                 icon="email"
  //                 placeholder={userData?.email}
  //                 disabled={true}
  //                 borderBottom
  //               />
  //             </Styled.FormGroup>
  //             <Styled.FormGroup className="inline">
  //               <label>
  //                 <FormattedMessage id="global.forms.labels.birthday" />
  //               </label>
  //               <InfoInput
  //                 error={errors.birthDate}
  //                 value={formFields.birthDate}
  //                 onChange={(e) =>
  //                   inputHandlerDate(maskDate(e.target.value), e.target.name)
  //                 }
  //                 name="birthDate"
  //                 icon="pencil"
  //                 placeholder="YYYY-MM-DD"
  //                 required
  //               />
  //             </Styled.FormGroup>
  //             {/* <Styled.FormGroup className="inline">
  //             <label>
  //               <FormattedMessage id="global.forms.labels.languages" />
  //             </label>
  //             <Select
  //               clearable
  //               multiple
  //               values={values.languages}
  //               options={LANGUAGE_SPEAK_OPTIONS}
  //               onChange={(value) => this.onSelectChange(value, "languages")}
  //             />
  //           </Styled.FormGroup> */}
  //           </Styled.InnerBox>
  //         </Styled.Col>
  //         <Styled.Col className="full-width">
  //           <SectionTitle>
  //             <FormattedMessage id="settings.about" />
  //           </SectionTitle>
  //           <Styled.InnerBox className="no-margin">
  //             <InfoInput
  //               value={formFields.about}
  //               onChange={inputHandler}
  //               name="about"
  //               type="text"
  //               icon="pencil"
  //               placeholder="Like to play"
  //               borderBottom
  //             />
  //           </Styled.InnerBox>
  //         </Styled.Col>
  //       </Styled.Row>
  //     </ContentBox>
  //   );
  // }
  return <div>{renderForm()}</div>;
};
const mapStateToProps = (state) => {
  return {
    countriesList: state.countriesList,
    devicesList: state.devicesList,
    userData: state.userData,
    interfaceLang: state.interfaceLang,
    languagesList: state.languagesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: () => dispatch(getUserData()),
    setInterfaceLang: (lang) => dispatch(setInterfaceLang(lang)),
    dispatchShowQrModal: () => dispatch(showQrModal()),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)
);

const StyledInfo = styled.div`
  padding: 20px 0px;
  margin: 20px 0px;

  & > header {
    padding: 25px 30px;

    ${({ img }) =>
      img &&
      css`
        background: url(${img}) no-repeat center;
        background-size: cover;
      `}

    & > section {
      &:first-of-type {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 20px;

        & > div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: relative;

          & > .red-circle {
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: var(--red);
            border-radius: 50%;
            top: 1px;
            right: 0px;
          }
        }
      }

      &:nth-child(2) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-around;

        & > div {
          &:first-of-type {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            cursor: pointer;

            & > p {
              font-size: 12px;
              font-weight: 300;
              font-style: normal;
              letter-spacing: normal;
              line-height: 17.99px;
              text-align: left;
              text-decoration: underline;
            }
          }
        }
      }

      &:last-of-type {
        width: max-content;
        margin: 20px auto 0;
        padding-left: 20px;

        & > div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          & > p {
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
            text-transform: uppercase;
            margin-right: 5px;

            & > span {
              font-weight: 700;
              color: #ce8926;
            }
          }
        }

        & > p {
          color: #f6a020;
          font-size: 12px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          line-height: 17.99px;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
  & > main {
    & > form {
      display: flex;
   flex-direction: row;
justify-content: space-around;
      @media (max-width: 768px) {
        flex-direction : column;
 
  }
}
    }
  }
  & > footer {
    padding: 20px 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    gap: 30px;
  }
`;
