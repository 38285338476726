import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";
import { isAuthenticated } from "../../../../helpers";
import { convertDate } from "./constants";
import AsiaLogo from "../../../../assets/asia-logo.jpeg";
import PassportLogo from "../../../../assets/logo-sm.png";
import styles from "./styles.module.scss";
import starImage from "../../../../assets/svg/Star.svg";
import starGoldImage from "../../../../assets/svg/StarGold.svg";

const TournamentItem = ({
  id,
  name,
  registrationEndedAt,
  registrationStartedAt,
  follow,
  game,
}) => {
  const [star, tongleStar] = useState(!follow);

  const history = useHistory();

  const followHandler = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowTournament,
      pathKeys: {
        tournamentId: id,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowTournament,
              pathKeys: {
                tournamentId: id,
              },
            })
              .then((res) => {
                toast.success(`You unfollowed ${name}`);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followTournament,
              data: {
                tournamentId: id,
              },
            })
              .then((res) => {
                toast.success(`You followed ${name}`);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));

    if (star) {
      tongleStar(false);
    } else tongleStar(true);
  };

  // method
  function goToUrl(url) {
    history.push(url);
  }

  return (
    <li
      className={classNames("calendarItemBorder", styles.tournament)}
      key={id}
    >
      <img
        src={game?.logo ? game?.logo : PassportLogo}
        className={styles.tournamentLogo}
        onClick={() => goToUrl(`/battleCup/${id}/information`)}
      />
      <div
        className={styles.tournamentDescription}
        onClick={() => goToUrl(`/battleCup/${id}/information`)}
      >
        <h4 className={styles.tournamentName}>
          {name ? name : "Tournament" + game?.title}
        </h4>
        <div className={styles.tournamentDecoration} />
        <p className={styles.tournamentDate}>
          {convertDate(registrationStartedAt, registrationEndedAt)}
        </p>
      </div>
      {!!isAuthenticated() && (
        <img
          // If followed Golden else Default
          src={star ? starImage : starGoldImage}
          className={styles.tournament__star}
          onClick={() => followHandler()}
        />
      )}
    </li>
  );
};

export default TournamentItem;
