import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import ImagesComponent from "./components/images-row";
import TeamContactsComponent from "./components/team-contacts-row";

import styles from "./styles.module.scss";

const CommunityBody = ({ community }) => {
  return (
    <div className={styles.events__about__wrapper}>
      <section className={styles.events__about}>
        {!!community?.organizerLogo?.length && (
          <ImagesComponent
            organizerLogo={true}
            images={community?.organizerLogo}
          />
        )}

        {!!community?.teamOfOrganizer?.length && (
          <TeamContactsComponent
            teamOfOrganizer={true}
            information={community?.teamOfOrganizer}
          />
        )}
        {!!community?.contact?.length && (
          <TeamContactsComponent
            contacts={true}
            information={community?.contact}
          />
        )}
        <h4 className={styles.events__about__title}>
          <FormattedMessage id="battlecup.information.social" />
        </h4>
        <article
          className={classNames("eventBodyBg", styles.events__organizer__team)}
        >
          {community?.socialNetworks?.discord && (
            <div className={styles.events__organizer__team__container}>
              <h1 className={styles.organizer__card__title}>Discord :</h1>
              <div className={styles.organizer__card}>
                <a
                  href={community?.socialNetworks?.discord}
                  className={styles.organizer__card__title}
                  style={{ color: "aqua" }}
                  target="_blank"
                >
                  {community?.socialNetworks?.discord}
                </a>
              </div>
            </div>
          )}
          {community?.socialNetworks?.twitter && (
            <div className={styles.events__organizer__team__container}>
              <h1 className={styles.organizer__card__title}>Twitter :</h1>
              <div className={styles.organizer__card}>
                <a
                  href={community?.socialNetworks?.twitter}
                  className={styles.organizer__card__title}
                  style={{ color: "aqua" }}
                  target="_blank"
                >
                  {community?.socialNetworks?.twitter}
                </a>
              </div>
            </div>
          )}{" "}
          {community?.socialNetworks?.facebook && (
            <div className={styles.events__organizer__team__container}>
              <h1 className={styles.organizer__card__title}>Facebook :</h1>
              <div className={styles.organizer__card}>
                <a
                  href={community?.socialNetworks?.facebook}
                  className={styles.organizer__card__title}
                  style={{ color: "aqua" }}
                  target="_blank"
                >
                  {community?.socialNetworks?.facebook}
                </a>
              </div>
            </div>
          )}{" "}
          {community?.socialNetworks?.instagram && (
            <div className={styles.events__organizer__team__container}>
              <h1 className={styles.organizer__card__title}>Instagram :</h1>
              <div className={styles.organizer__card}>
                <a
                  href={community?.socialNetworks?.instagram}
                  className={styles.organizer__card__title}
                  style={{ color: "aqua" }}
                  target="_blank"
                >
                  {community?.socialNetworks?.instagram}
                </a>
              </div>
            </div>
          )}
        </article>
        {!!community?.partnerLogo?.length && (
          <ImagesComponent partnerLogo={true} images={community?.partnerLogo} />
        )}
        {!!community?.sponsorLogo?.length && (
          <ImagesComponent sponsorLogo={true} images={community?.sponsorLogo} />
        )}
        {!!community?.anyPhotos?.length && (
          <ImagesComponent anyPhotos={true} images={community?.anyPhotos} />
        )}
      </section>
    </div>
  );
};

export default CommunityBody;
