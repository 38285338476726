import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { API, API_ROUTER } from "../../../../api";

import EventItem from "../../../../components/list-items/components/eventItem";
import { Preloader } from "../../../../components/UI";
import { CustomSelect } from "../../../../components/form";

import { isAuthenticated } from "../../../../helpers";
import { EVENT_STATUSES } from "./constatnts";

import styles from "./styles.module.scss";
import { Close } from "@material-ui/icons";

export const EventsList = () => {
  const [eventsList, setEventsList] = useState();
  const [pageEvents, setPageEvents] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthUser, setIsAuthUser] = useState(isAuthenticated());
  const [defaultStatus, setDefaultStatus] = useState(null);

  const getEventsList = () => {
    let urlParams = {};
    setIsLoading(true);

    if (defaultStatus) {
      urlParams["eventStatusIds"] = defaultStatus.id;
    }

    API.request({
      ...API_ROUTER.follow.getfollowedEvents,
      urlParams,
      pathKeys: {
        page: pageEvents,
        limit: 10,
      },
    })
      .then((res) => {
        setEventsList(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleChangeEvents = (event, value) => {
    setPageEvents(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const changeEventStatus = (val) => {
    const newVal = EVENT_STATUSES?.find((el) => el.id === val);

    if (newVal) {
      setDefaultStatus(newVal);
    }
  };

  const clearFilters = () => {
    setDefaultStatus(null);
  };

  useEffect(() => {
    getEventsList();
  }, [pageEvents, defaultStatus]);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <section className={styles.calendarList}>
      {/* <div className={styles.filters}>
                <CustomSelect
                    onChange={changeEventStatus}
                    options={EVENT_STATUSES}
                    label="events.status.selectStatus"
                    defaultValue={defaultStatus}
                />
                <button className={styles.clearButton} onClick={clearFilters}>
               
                    <Close />
                </button>
            </div> */}
      {eventsList?.follows?.map((events) => (
        <EventItem {...events} key={events.id} />
      ))}

      {!eventsList?.follows?.length && (
        <div className="empty-data">
          <FormattedMessage id="calendar.noEvents" />
        </div>
      )}

      {!eventsList?.paginator?.hasNextPage |
      (eventsList?.follows?.length > 9) ? (
        <div className={styles.paginatorWrapper}>
          <Pagination
            classes={{ ul: styles.pagination }}
            count={eventsList?.paginator?.lastPage}
            page={eventsList?.paginator?.currentPage}
            onChange={handleChangeEvents}
            variant="outlined"
          />
        </div>
      ) : (
        false
      )}
    </section>
  );
};

export default EventsList;
