import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isAuthenticated } from "../../../helpers";
import { API, API_ROUTER } from "../../../api";
import CupRules from "../CupRules";
import CupTop from "../../../components/cup-top";
import GlobalStyles from "../../../assets/styles/global";
import { Container } from "../../../components/UI";
export default function CupStatisticsHOC({ ...props }) {
  const [tournaments, setTournaments] = useState();
  const [tournamentDoesnotExistModal, setTournamentDoesnotExistModal] =
    useState(false);
  let { id } = useParams();
  useEffect(() => {
    let isAuthTournamentData;
    if (isAuthenticated()) {
      isAuthTournamentData = API_ROUTER.tournaments.getAuthCurrentTournament;
    } else {
      isAuthTournamentData = API_ROUTER.tournaments.getCurrentTournament;
    }
    API.request({
      ...isAuthTournamentData,
      pathKeys: {
        tournamentId: id,
      },
    })
      .then((res) => {
        setTournaments(res);
        console.log(res);
      })
      .catch((err) =>
        err
          ? setTournamentDoesnotExistModal(true)
          : setTournamentDoesnotExistModal(false)
      );
  }, []);
  return (
    <>
      <GlobalStyles />
      <Container>
        <CupTop
          tournament={tournaments}
          tournamentDoesnotExistModal={tournamentDoesnotExistModal}
          {...props}
        />
        <CupRules tournament={tournaments} {...props} />
      </Container>
    </>
  );
}
