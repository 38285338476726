import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useDraggable } from "react-use-draggable-scroll";
import { toast } from "react-toastify";

import { API, API_ROUTER } from "../../../../../api";

import { CheckBox } from "../../../../../components/UI";
import noImage from "../../../../../assets/images/no-image.webp";

import SendResultModal from "./modal";

import styles from "./styles.module.scss";

export const MatchMembersFFA = ({ match, isUpdateUserData }) => {
  const history = useHistory();
  const dragRef = useRef();
  const { events } = useDraggable(dragRef);

  const [openSendResultModal, setOpenSendResultModal] = useState(false);
  const [membersState, setMembersState] = useState([]);

  function inputHandler(e, id, field) {
    const { value } = e.target;
    membersState.map((el) => {
      if (el.id === id) {
        el[field] = Number(value);
        return setMembersState([...membersState]);
      }
    });
  }
  const onCheckboxChange = (id) => {
    membersState.map((el) => {
      if (el.id === id) {
        el.isWinner = !el.isWinner;
        return setMembersState([...membersState]);
      }
    });
  };
  // если участник - команда, то выводим название команды, если нет, то никнейм
  const membersData = (member) => {
    if (member?.team === null) {
      return (
        <td
          onClick={(e) => goToUrl(`/id/${member?.user?.url}`)}
          className={classNames("bracketFFANameHover", styles.name)}
        >
          <span className={styles.logo}>
            <img src={member?.user?.avatar ? member?.user?.avatar : noImage} />
          </span>
          {member?.user?.nickname}
        </td>
      );
    } else if (member?.user === null) {
      return (
        <td
          onClick={(e) => goToUrl(`/teams/team/${member?.team?.id}`)}
          className={classNames("bracketFFANameHover", styles.name)}
        >
          <span className={styles.logo}>
            <img src={member?.team?.logo ? member?.team?.logo : noImage} />
          </span>
          {member?.team?.name}
        </td>
      );
    } else {
      return (
        <td className={classNames("bracketFFANameHover", styles.name)}>
          <span className={styles.logo}>
            <img src={noImage} />
          </span>
          name
        </td>
      );
    }
  };
  const goToUrl = (url) => {
    history.push(url);
  };

  // проверка на то, что исполнитель - судья и он может менять результат
  const executorIsJudge = () => {
    return match?.canChangeResult && match?.executorIsJudge;
  };
  // отправка результатов
  const onHandleSubmit = () => {
    // проверка всех чекбоксов, должен быть хотя бы один чекбокс true
    const arrayOfLosers = [];
    membersState?.map((el) => {
      arrayOfLosers.push(el?.isWinner);
    });
    if (!arrayOfLosers.includes(true)) {
      return toast.error("At least one winner must be");
    } else
      API.request({
        ...API_ROUTER.tournaments.setResultMatchFFA,
        pathKeys: {
          matchId: match.matchId,
        },
        data: {
          tournamentMemberResults: membersState,
        },
      })
        .then((res) => {
          toast.success("Results saved");
        })
        .catch((err) => {
          if (err?.data?.errors) {
            const errorText = Object.values(err?.data?.errors);
            toast.error(errorText);
          }
          console.log(err);
        });
  };
  useEffect(() => {
    setMembersState(match?.stageFfa?.matchFfaMembers);
  }, [match?.stageFfa?.matchFfaMembers]);
  return (
    <>
      <div
        {...events}
        ref={dragRef}
        className={classNames("matchMembersWrapperBg", styles.detailWrap)}
      >
        <div className={styles.scrollWrap}>
          <div className={styles.tableWrap}>
            <table
              className={classNames("bracketTableFFAColors", styles.table)}
            >
              <caption>MATCH MEMBERS FFA</caption>
              <colgroup>
                <col width="6.7%" />
                <col width="*" />
                <col width="12%" />
                <col width="17.5%" />
                <col width="12%" />
                <col width="17.5%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" colSpan={2}>
                    <FormattedMessage id="match.ticker.team" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="match.ticker.placement" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="match.ticker.kills" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="match.ticker.winlose" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="match.ticker.total" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {membersState?.map((el, index) => (
                  <tr key={el?.id}>
                    <td>#{index + 1}</td>
                    {/* <td className={styles.name}>
                      <span className={styles.logo}>
                        <img src={noImage} />
                      </span>
                      dasdas
                    </td> */}
                    {membersData(el?.tournamentMember)}
                    {executorIsJudge() ? (
                      <>
                        <td>
                          <input
                            onChange={(e) =>
                              inputHandler(e, el?.id, "placement")
                            }
                            type="number"
                            min="0"
                            placeholder={el?.placement}
                            value={el?.placement}
                          />
                        </td>
                        <td>
                          <input
                            onChange={(e) => inputHandler(e, el?.id, "kills")}
                            type="number"
                            min="0"
                            placeholder={el?.kills}
                            value={el?.kills}
                          />
                        </td>
                        <td>
                          <div className={styles.checkBox}>
                            <CheckBox
                              onChange={(e) => onCheckboxChange(el?.id)}
                              checked={el?.isWinner}
                              required
                              name="gdpr"
                            ></CheckBox>
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{el?.placement}</td>
                        <td>{el?.kills}</td>
                        {match?.status === "soon" ? (
                          <td>
                            <FormattedMessage id="match.ticker.soon" />
                          </td>
                        ) : (
                          <td>{el?.isWinner === true ? "Win" : "Lose"}</td>
                        )}
                      </>
                    )}

                    <td>{el?.totalScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {executorIsJudge() && (
        <>
          <div className={styles.sendResultWrapper}>
            <button
              onClick={(e) => setOpenSendResultModal(!openSendResultModal)}
              className={classNames(
                "sendResultButtonFFABg",
                styles.sendResultButton
              )}
            >
              <FormattedMessage id="match.ticker.send.result" />
            </button>
          </div>
          <SendResultModal
            openSendResultModal={openSendResultModal}
            setOpenSendResultModal={setOpenSendResultModal}
            onHandleSubmit={onHandleSubmit}
          />
        </>
      )}
    </>
  );
};

export default MatchMembersFFA;
