import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import classNames from "classnames";

import { API, API_ROUTER } from "../../api";

import { hideQrModal, showQrModal } from "../../redux/actions";
import UserDoesnotExistModal from "./modal/user-not-exist";
import Dashboard from "./Dashboard";
import getPublicUserProfile from "../../helpers/user/getPublicUserProfile";
import SelectSignup from "../../components/UI/forms/select-signup";
import { Styled } from "../../components/auth-modal/style";
import { Modal } from "../../components/UI";
import { Container } from "../../components/UI";

import qr from "../../assets/images/qr-code.png";
import logo from "../../assets/images/passport-logo.png";
// import logo from "../../assets/images/hogasia.png";

// const getDefaultErrors = () => {
//   return {
//     country: "",
//   };
// };
const Profile = (props) => {
  const userData = useSelector((state) => state.userData);
  const showQrModal = useSelector((state) => state.showQrModal);

  const [userQr, setUserQr] = useState("");
  const [userProfile, setUserProfile] = useState();
  const [isLoad, setIsLoad] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [teams, setTeams] = useState();
  const [selectTeam, setSelectTeam] = useState([]);
  const [userDoesnotExist, setUserDoesNotExist] = useState(false);

  // IF LOGIN WITH FACEBOOK AND GOOGLE
  // const [selectCountry, setSelectCountry] = useState([]);
  // const [errors, setErrors] = useState(getDefaultErrors);
  // const country = selectCountry[0]?.value;

  const dispatch = useDispatch();

  const teamId = selectTeam[0]?.value;
  const new_obj = teams?.map((obj) => {
    return { value: obj?.id, label: obj?.name };
  });

  let { url } = useParams();

  function isCreator() {
    return userProfile?.id === userData?.id;
  }

  const getUserProfile = () => {
    setIsLoad(true);
    getPublicUserProfile(url)
      .then((res) => {
        setUserProfile(res);
      })
      .catch((err) => {
        toast.error(!!err?.data?.errors?.userUrl && "User does not exist"),
          setUserDoesNotExist(!!err?.data?.errors?.userUrl && true);
      })
      .finally(() => setIsLoad(false));
  };

  function getQR() {
    let userId = userProfile?.id;
    if (userId !== undefined) {
      API.request({
        ...API_ROUTER.user.getUserQR,
        pathKeys: { userId },
      })
        .then((res) => {
          setUserQr(res.url);
        })
        .catch((err) => console.log(err));
    } else {
      setUserQr(qr);
    }
  }

  const sendInvite = () => {
    if (!teamId) {
      return toast.error("select team");
    }
    API.request({
      ...API_ROUTER.teams.setIdUserInvite,
      pathKeys: {
        teamId: teamId,
      },
      data: {
        inviteeId: userProfile?.id,
      },
    })
      .then((res) => {
        toast.success("You have successfully invite user");
        dispatchShowQrModal();
      })
      .catch((err) => {
        toast.error(err.data && err.data.message);
      });
  };

  const getTeamsByRole = () => {
    if (userData?.id === undefined) {
      return;
    }
    API.request({
      ...API_ROUTER.teams.getTeamByRole,
      pathKeys: {
        userId: userData?.id,
        role: "owner",
      },
    })

      .then((res) => {
        setTeams(res.teams);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserProfile();
  }, [url]);

  useEffect(() => {
    getQR();
  }, [url]);

  useEffect(() => {
    getTeamsByRole();
  }, [showTeamModal]);
  // IF LOGIN WITH FACEBOOK AND GOOGLE

  // function updateData(e) {
  //   e.preventDefault();
  //   errors.country = isSelectEmpty(selectCountry);
  //   setErrors({ ...errors });
  //   if (!Object.values(errors).some((value) => value.length)) {
  //     API.request({
  //       ...API_ROUTER.user.updateAccount,
  //       data: {
  //         country: country,
  //       },
  //     })
  //       .then((res) => {
  //         window.location.href = `/login`;
  //       })
  //       .catch((err) => console.log(err));
  //   }
  //   toast.error("select country");
  // }
  // const countryPlaceholder = useIntl().formatMessage({
  //   id: "signup.countryPlaceholder",
  // });

  return (
    <>
      <Container>
        {isLoad && <LinearProgress />}
        {!isLoad && (
          <Dashboard
            {...props}
            url={url}
            isCreator={isCreator}
            id={userProfile?.id}
            setShowTeamModal={setShowTeamModal}
          />
        )}

        <Modal
          className="modal__wrapper"
          isTransparent={true}
          open={showQrModal}
          onClose={() => dispatch(hideQrModal())}
        >
          {showTeamModal ? (
            <div className={classNames("modalBg")}>
              <img
                className={classNames("qr__owl-eye")}
                src={logo}
                alt="owl-eye"
              />
              <p className="qr__title">
                <FormattedMessage id="profile.schoose.team" />
              </p>
              <SelectSignup
                required
                placeholder="Choose team"
                clearable
                options={new_obj}
                values={selectTeam}
                onChange={(e) => setSelectTeam(e, "team")}
              />
              <Styled.ButtonHolder>
                <button className="qr__hide-qr" onClick={sendInvite}>
                  <FormattedMessage id="profile.send.invite.team" />
                </button>
              </Styled.ButtonHolder>
            </div>
          ) : (
            <div className="modal">
              <img className="qr__owl-eye" src={logo} alt="owl-eye" />
              <p className="qr__title">
                {" "}
                <FormattedMessage id="idCard.title" />
              </p>
              <div className="qr">
                <img
                  className="qr__img"
                  src={userQr}
                  alt="QR code"
                  style={{ borderRadius: "15px" }}
                />
              </div>
              <Styled.ButtonHolder>
                <button
                  className="qr__hide-qr"
                  onClick={() => dispatch(hideQrModal())}
                >
                  <FormattedMessage id="global.buttons.close" />
                </button>
              </Styled.ButtonHolder>
            </div>
          )}
        </Modal>
        {!!userDoesnotExist && (
          <UserDoesnotExistModal
            userDoesnotExist={userDoesnotExist}
            userUrl={userData?.url}
          />
        )}

        {/* IF LOGIN WITH FACEBOOK AND GOOGLE */}

        {/* {!!isAuthenticated() && !isLoad ? (
        !!userData?.country && !!userData?.email ? (
          false
        ) : (
          <Modal
            className="modal__wrapper"
            isTransparent={true}
            open={true}
            onClose={dispatchHideQrModal}
          >
            <div className="modal">
              <img className="qr__owl-eye" src={logo} alt="owl-eye" />
              <p className="qr__title">Please enter your details to continue</p>
              <div className="qr__email-country-warning">
                {!isLoad ? (
                  userData?.email ? (
                    false
                  ) : (
                    <Input icon="email" />
                  )
                ) : (
                  false
                )}

                <SelectSignup
                  required
                  placeholder={countryPlaceholder}
                  clearable
                  options={countriesList}
                  values={selectCountry}
                  onChange={(e) => setSelectCountry(e, "country")}
                />
              </div>
              <Styled.ButtonHolder>
                <button className="qr__hide-qr" onClick={updateData}>
                  Save
                </button>
              </Styled.ButtonHolder>
            </div>
          </Modal>
        )
      ) : (
        false
      )} */}
      </Container>
    </>
  );
};

export default Profile;
