import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { API, API_ROUTER } from "../../../api";

import { isAuthenticated } from "../../../helpers";
import CupInformation from "../CupInformation";
import CupTop from "../../../components/cup-top";

import GlobalStyles from "../../../assets/styles/global";
import { Container } from "../../../components/UI";

export default function CupHoc({ ...props }) {
  const [tournaments, setTournaments] = useState();
  const [participationTypeData, setParticipationTypeData] = useState(null);
  const [tournamentDoesnotExistModal, setTournamentDoesnotExistModal] =
    useState(false);
  let { id } = useParams();

  const getCurrentTournament = () => {
    let isAuthTournamentData;
    if (isAuthenticated()) {
      isAuthTournamentData = API_ROUTER.tournaments.getAuthCurrentTournament;
    } else {
      isAuthTournamentData = API_ROUTER.tournaments.getCurrentTournament;
    }

    API.request({
      ...isAuthTournamentData,
      pathKeys: {
        tournamentId: id,
      },
    })
      .then((res) => {
        setTournaments(res);
        setParticipationTypeData(res?.participationType);
      })
      .catch((err) =>
        err
          ? setTournamentDoesnotExistModal(true)
          : setTournamentDoesnotExistModal(false)
      );
  };

  useEffect(() => {
    getCurrentTournament();
  }, []);

  return (
    <>
      <GlobalStyles />
      <Container>
        <CupTop
          tournament={tournaments}
          tournamentDoesnotExistModal={tournamentDoesnotExistModal}
          {...props}
        />
        {tournaments && (
          <CupInformation
            participationTypeData={participationTypeData}
            tournament={tournaments}
            {...props}
          />
        )}
      </Container>
    </>
  );
}
