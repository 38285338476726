import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const MatchStatistics = ({ match }) => {
  const getNameTeam = (val) => {
    if (val?.team?.name) {
      return val.team.name;
    }

    if (val?.user?.nickname) {
      return val.user.nickname;
    }

    return "-";
  };

  return (
    <div className={classNames("matchStatisticRoundBg", styles.roundDetails)}>
      <p className={styles.title}>
        <FormattedMessage id="match.ticker.statistics" />
      </p>
      <p className={styles.subtitle}>
        (<FormattedMessage id="match.ticker.rounds" />)
      </p>
      <div className={styles.scoreWrapper}>
        <div className={styles.nameTeam}>
          {getNameTeam(match?.tournamentMember1)}
        </div>
        <div className={styles.score}>
          <div className={styles.rounds}>
            <p>{match?.tournamentMemberScore1}</p>
            <p>:</p>
            <p>{match?.tournamentMemberScore2}</p>
          </div>
        </div>
        <div className={styles.nameTeam}>
          {getNameTeam(match?.tournamentMember2)}
        </div>
      </div>
    </div>
  );
};

export default MatchStatistics;
