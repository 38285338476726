import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import moment from "moment";
import classNames from "classnames";
import { InputLabel, MenuItem, Select, Button } from "@material-ui/core";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";

import { API, API_ROUTER } from "../../../../api";

import { DateInput } from "../../../UI";
import BracketModal from "./components/modal";

import { DOUBLE_ELIMINATION_TYPE, GRAND_FINAL_TYPE } from "./constants";
import LowerRound from "./components";

import s from "./styles.module.scss";

const TransModal = styled(Dialog)`
  .MuiPaper-root {
    overflow: visible;
    border-radius: 0;
    background-color: transparent;
  }
`;
const useStyles = makeStyles((theme) => ({
  paper: {
    background: "#707070",
  },
}));

export const PlayOff = ({
  requestData,
  grandFinal,
  tournamentMember,
  isAllowRoundUpdate,
}) => {
  const ref = useRef();
  const dragRef = useRef();
  const { events } = useDraggable(dragRef);
  const classes = useStyles();

  const history = useHistory();

  const [data, setData] = useState();
  const [nextMatchId, setNextMatchId] = useState();
  const [currentMatchId, setCurrentMatchId] = useState();
  const [nextLoserMatchId, setNextLoserMatchId] = useState();
  const [openBracketModal, setOpenBracketModal] = useState(false);
  const [loserMatchRef, setLoserMatchRef] = useState(false);
  const [matchItem, setMatchItem] = useState();
  const [openRoundChange, setOpenRoundChange] = useState(false);
  const [inputTime, setInputTime] = useState();
  const [inputDate, setInputDate] = useState();
  const [roundId, setRoudnId] = useState();

  const [roundBestOf, setRoundBestOf] = useState("");

  const transformData = () => {
    if (requestData?.basket?.length > 1) {
      const maxLengthBasket = requestData?.basket?.reduce(
        (sum, current) =>
          current?.cols?.length > sum ? current?.cols?.length : sum,
        0
      );

      const newArr = requestData?.basket?.splice(
        0,
        requestData?.basket?.length - 1
      );
      const filterArr = newArr?.map((el, index) => {
        if (el?.cols?.length < maxLengthBasket) {
          let newVal = el?.cols;
          for (let i = 0; i <= maxLengthBasket - el?.cols?.length; i++) {
            newVal.push({ isFiction: true });
          }

          return {
            ...el,
            cols: newVal,
            type: "fiction",
          };
        }

        return el;
      });
      return setData({
        ...requestData,
        basket: filterArr,
      });
    }

    return setData({ ...requestData });
  };

  const getTeamName = (val) => {
    const id = val?.tournamentMemberId || val?.id;
    if (!id) {
      return "";
    }

    const findTeam = tournamentMember?.find((el) => el?.id === id);

    if (findTeam) {
      return findTeam?.team?.name || findTeam?.user?.nickname;
    }

    return "";
  };

  const getArrayPairs = (arr) => {
    const newArr = (arr || []).reduce(function (result, value, index, array) {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, []);

    return newArr;
  };

  const getArrayFours = (arr) => {
    const newArr = (arr || []).reduce(function (result, value, index, array) {
      if (index % 4 === 0) result.push(array.slice(index, index + 4));
      return result;
    }, []);

    return newArr;
  };

  const getClassScore = (score, arr) => {
    let maxValue = (arr || []).reduce((sum, current) =>
      sum?.score < current ? current : sum?.score
    );
    if (!score) {
      return "";
    }

    if (score === maxValue) {
      return s.playerPont_orange;
    }

    return "";
  };
  const getClassScoreNew = (el, block) => {
    let maxScore = Math.max(el[0]?.score, el[1]?.score);
    if (!el) {
      return "playerPontBg";
    }
    if (el[0]?.score === el[1]?.score) {
      return "playerPontBg";
    }

    if (block?.score === maxScore) {
      return s.playerPont_orange;
    }

    return "playerPontBg";
  };

  const goToMatchTicker = (id) => {
    if (id) {
      return history.push(`/match-discussion/${id}`);
    }
  };

  const getScore = (val) => {
    if (!val.tournamentMemberId) {
      return "-";
    }

    if (Number.isFinite(val?.score)) {
      return val?.score;
    }

    return "-";
  };

  const getClassRow = (selectRow, selectRowIndex) => {
    if (!selectRow[selectRowIndex - 1]) {
      return s.play_offRoundRowCenter;
    }

    if (
      selectRowIndex &&
      selectRow[selectRowIndex - 1]?.rows?.length ===
        selectRow[selectRowIndex]?.rows?.length * 2
    ) {
      return s.play_offRoundRow;
    }

    return s.play_offRoundRowCenter;
  };

  const getStyleBorder = (basketItem, cols, index) => {
    if (
      basketItem[index - 1]?.rows?.length >=
        basketItem[index]?.rows?.length * 2 &&
      basketItem[index - 2]?.rows?.length >=
        basketItem[index - 1]?.rows?.length * 2 &&
      basketItem[index]?.rows?.length > 2
    ) {
      return s.borderTeamFullHeight;
    }

    return s.borderTeam;
  };
  console.log(inputDate, inputTime);

  const handleChangeBestOf = (event) => {
    setRoundBestOf(event.target.value);
  };
  const setTimeRound = (time, bestOf) => {
    if (time) {
      const correctTime = time?.split(" ")[1].split(":");
      setInputTime(`${correctTime[0]}:${correctTime[1]}`);
      setInputDate(new Date(time?.split(" ")[0]));
    }
    if (bestOf) {
      switch (bestOf) {
        case "Bo1":
          setRoundBestOf(1);
          break;
        case "Bo3":
          setRoundBestOf(2);
          break;
        case "Bo5":
          setRoundBestOf(3);
          break;
        case "Bo7":
          setRoundBestOf(4);
          break;
      }
    }
  };
  const scrollToErrorField = () => {
    setImmediate(() =>
      ref.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      })
    );
  };

  function setMatchId(nextMatchId, currentMatchId, nextLoserMatchId) {
    setCurrentMatchId(currentMatchId);
    setNextMatchId(nextMatchId ? nextMatchId : currentMatchId);
    setNextLoserMatchId(nextLoserMatchId ? nextLoserMatchId : currentMatchId);
  }

  const applyRoundSettings = () => {
    API.request({
      ...API_ROUTER.tournaments.setRoundSettings,
      pathKeys: {
        roundId: roundId,
      },
      data: {
        gameToWin: roundBestOf,
        activeAt:
          moment(inputDate).format("YYYY-MM-DD") + " " + inputTime + ":00",
      },
    })
      .then((res) => {
        toast.success("Round settings changed successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  };
  useEffect(() => {
    transformData();
  }, []);
  console.log(
    roundBestOf,
    moment(inputDate).format("YYYY-MM-DD") + " " + inputTime + ":00"
  );
  return (
    <div
      {...events}
      ref={dragRef}
      className={classNames("play_offContainerBg", s.play_offContainer)}
    >
      <TransModal disableEscapeKeyDown open={openRoundChange}>
        <DialogContent className={classNames("modalBg", s.modal)}>
          <div className={s.modalContainer}>
            <InputLabel className={s.roundChangeTitle}>Time</InputLabel>
            <input
              className={s.roundChangeInputTime}
              type="time"
              value={inputTime}
              onChange={(e) => setInputTime(e.target.value)}
            />
            <InputLabel className={s.roundChangeTitle}>Date</InputLabel>
            <DateInput selected={inputDate} onChange={setInputDate} />
            <InputLabel
              style={{ marginTop: "25px" }}
              className={s.roundChangeTitle}
            >
              Match's format
            </InputLabel>
            <Select
              className={s.roundChangeSelect}
              value={roundBestOf}
              onChange={handleChangeBestOf}
              label="Match's format"
              MenuProps={{
                classes: {
                  paper: classes.paper,
                },
              }}
              inputProps={{
                classes: {
                  root: classes.input,
                },
              }}
            >
              <MenuItem value={1}>BO1</MenuItem>
              <MenuItem value={2}>BO3</MenuItem>
              <MenuItem value={3}>BO5</MenuItem>
              <MenuItem value={4}>BO7</MenuItem>
            </Select>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() =>
                  isAllowRoundUpdate && setOpenRoundChange(!openRoundChange)
                }
              >
                close
              </Button>
              <Button onClick={() => applyRoundSettings()}>save</Button>
            </div>
          </div>
        </DialogContent>
      </TransModal>
      {/* <div
        className={s.roundChange}
        style={{
          display: openRoundChange ? "flex" : "none",
        }}
      >
        <input
          className={s.roundChangeInputTime}
          type="time"
          value={inputTime}
          onChange={(e) => setInputTime(e.target.value)}
        />

        <DateInput
          style={{ background: "#9a1ec779" }}
          selected={inputDate}
          onChange={setInputDate}
        />
        <InputLabel id="demo-simple-select-label">Games To Win</InputLabel>
        <Select
          style={{ background: "#9a1ec779" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value="1"
          label="Games To Win"
        >
          <MenuItem style={{ background: "#9a1ec779" }} value="1">
            1
          </MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
          <MenuItem value="8">8</MenuItem>
          <MenuItem value="9">9</MenuItem>
          <MenuItem value="10">10</MenuItem>
        </Select>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => setOpenRoundChange(!openRoundChange)}>
            close
          </Button>
          <Button onClick={() => setOpenRoundChange(!openRoundChange)}>
            save
          </Button>
        </div>
      </div> */}
      {!data ||
        (!data?.basket?.length && <div className={s.noData}>No data</div>)}
      {!!(data && data?.basket?.length) && (
        <>
          <div>
            {data?.basket?.length &&
              [data?.basket[0]].map((basketItem, index) => (
                <div key={`basket-item-${basketItem.type}-${index}`}>
                  {basketItem?.type !== GRAND_FINAL_TYPE && (
                    <div
                      key={basketItem?.type}
                      className={s.play_offFlexContainer}
                    >
                      {basketItem?.cols?.map((colsItem, i) => (
                        <>
                          {colsItem?.rows?.length ||
                          basketItem?.type !== "fiction" ? (
                            <div
                              className={getClassRow(basketItem?.cols, i)}
                              key={`col-item-row-${basketItem.type}-${i}`}
                              id={`${i}`}
                            >
                              <div
                                className={s.roundTitle}
                                onClick={(e) => {
                                  isAllowRoundUpdate &&
                                    setOpenRoundChange(!openRoundChange);
                                  setTimeRound(
                                    colsItem?.rows[0]?.round?.startedAt,
                                    colsItem?.rows[0]?.round?.mode
                                  );

                                  setRoudnId(colsItem?.rows[0]?.round?.id);
                                }}
                              >
                                {colsItem?.roundName}
                              </div>

                              {getArrayFours(colsItem?.rows)?.map(
                                (el, index) => (
                                  <div
                                    className={classNames(
                                      "teamsBlockBordersAndBg",
                                      colsItem?.rows.length > 2
                                        ? s.teamsBlock
                                        : s.teamsBlock_forOne
                                    )}
                                    key={`item-teams-${index}`}
                                  >
                                    <div
                                      className={getStyleBorder(
                                        basketItem?.cols,
                                        colsItem,
                                        i
                                      )}
                                    />

                                    {getArrayPairs(el)?.map(
                                      (teamItem, index) => (
                                        <div
                                          onClick={(e) =>
                                            setMatchItem(teamItem[0])
                                          }
                                          key={`${basketItem.type}-pairs-${index}`}
                                          className={classNames(
                                            "teamBlockBg",
                                            teamItem.length > 1
                                              ? s.teamBlock
                                              : s.teamBlock_forOne
                                          )}
                                        >
                                          {teamItem?.map((block, iBlock) => (
                                            <div
                                              key={`pairs-item-${block?.id}-${iBlock}`}
                                            >
                                              <div className={s.matchNumber}>
                                                {block?.id}
                                              </div>
                                              <div
                                                ref={
                                                  nextMatchId ===
                                                  teamItem[0]?.matchId
                                                    ? ref
                                                    : null
                                                }
                                                style={
                                                  nextMatchId ===
                                                  teamItem[0]?.matchId
                                                    ? {
                                                        backgroundColor:
                                                          "#755f33",
                                                      }
                                                    : {}
                                                }
                                                className={classNames(
                                                  "playerInfoBgAndBorder",
                                                  s.player
                                                )}
                                                onClick={() => {
                                                  setOpenBracketModal(
                                                    !openBracketModal
                                                  );
                                                }}
                                              >
                                                <span className={s.playerTitle}>
                                                  {getTeamName(block)}
                                                </span>
                                                <div
                                                  className={classNames(
                                                    s.playerPont,
                                                    getClassScoreNew(
                                                      teamItem,
                                                      block
                                                    )
                                                  )}
                                                >
                                                  {getScore(block)}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <div className={s.play_offRoundRowCenterFiction}>
                              <div className={s.teamsBlockOneFiction} />
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            {data?.basket?.length > 1 && (
              <LowerRound
                data={data?.basket[1]}
                tournamentMember={tournamentMember}
                nextMatchId={nextMatchId}
                nextLoserMatchId={nextLoserMatchId}
                setMatchId={setMatchId}
                setMatchItem={setMatchItem}
                setOpenBracketModal={setOpenBracketModal}
                openBracketModal={openBracketModal}
                ref={ref}
              />
            )}
          </div>
        </>
      )}
      {!!(
        grandFinal &&
        data?.basket?.length &&
        data?.type === DOUBLE_ELIMINATION_TYPE
      ) && (
        <div className={s.play_offRoundRow2}>
          <div
            className={s.roundTitle}
          >{`${grandFinal?.cols[0]?.roundName}`}</div>
          <div className={classNames("teamBlockBg", s.teamBlock2)}>
            <div className={s.matchNumber}></div>
            {grandFinal?.cols[0]?.rows?.map((grandItem, index) => (
              <div
                style={
                  nextMatchId === grandItem?.matchId
                    ? {
                        backgroundColor: "#755f33",
                      }
                    : {}
                }
                onClick={() => {
                  setMatchItem(grandItem);
                  setOpenBracketModal(!openBracketModal);
                }}
                ref={nextMatchId === grandItem?.matchId ? ref : null}
                className={classNames("playerInfoBgAndBorder", s.player)}
                key={`grandItem-${grandItem?.matchId}-${index}`}
              >
                <span className={s.playerTitle}>{getTeamName(grandItem)}</span>
                <div
                  className={classNames(
                    s.playerPont,
                    getClassScoreNew(grandFinal?.cols[0]?.rows, grandItem)
                  )}
                >
                  {getScore(grandItem)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <BracketModal
        open={openBracketModal}
        nextLoserMatchId={nextLoserMatchId}
        loserMatchRef={loserMatchRef}
        matchItem={matchItem}
        setNextMatchId={setNextMatchId}
        setCurrentMatchId={setCurrentMatchId}
        setNextLoserMatchId={setNextLoserMatchId}
        setOpenBracketModal={setOpenBracketModal}
        scrollToErrorField={scrollToErrorField}
        setLoserMatchRef={setLoserMatchRef}
      />
    </div>
  );
};

export default PlayOff;
